import React from "react";
import NavBar from "../Components/NavBar";
import FirstImageJPG from "../images/DinnerPageImages/FirstImage.jpg";
import FirstImageWEBP from "../images/DinnerPageImages/FirstImage.webp";
import FirstImageJPGMobi from "../images/DinnerPageImages/FirstImageMobi.jpg";
import FirstImageWEBPMobi from "../images/DinnerPageImages/FirstImageMobi.webp";
import { Grid, Typography, IconButton, Tooltip, Button, Menu, MenuItem, Fade } from "@mui/material";
import { GiChiliPepper, GiPeanut } from "react-icons/gi";
import GlutenFree from "../images/glutenFreeImage.png";
import { FaLeaf } from "react-icons/fa";
import { IoRestaurantSharp } from "react-icons/io5";
import { useWidth } from "../WidthContext";
import Footer from "../Components/Footer";
import { CgMenuGridO } from "react-icons/cg";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Scroll } from "react-fns";
import DinnerImage1PNG from "../images/DinnerPageImages/DinnerImage1.png";
import DinnerImage2PNG from "../images/DinnerPageImages/DinnerImage2.png";
import DinnerImage3PNG from "../images/DinnerPageImages/DinnerImage3.png";
import DinnerImage4PNG from "../images/DinnerPageImages/DinnerImage4.png";
import DinnerImage1WEBP from "../images/DinnerPageImages/DinnerImage1.webp";
import DinnerImage2WEBP from "../images/DinnerPageImages/DinnerImage2.webp";
import DinnerImage3WEBP from "../images/DinnerPageImages/DinnerImage3.webp";
import DinnerImage4WEBP from "../images/DinnerPageImages/DinnerImage4.webp";

import { Appetizers, SoupAppetizer, Pho, OtherSoups, ThaiStirFry, ThaiCurry, FromTheWok, FriedRice, VermicelliBowl, RicePlate, SideOrders, Dessert } from "./assets";

const DinnerPage = () => {
    const { width } = useWidth();
    const [menuElement, setMenuElement] = React.useState(null);
    const open = Boolean(menuElement);

    const handleClick = (event) => {
        setMenuElement(event.currentTarget);
    };
    const handleClose = () => {
        setMenuElement(null);
    };

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden"}}>
            {/* Dinner Menu Banner */}
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "50vh"}}>
                <picture style={{height: "50vh"}}>
                    <source srcSet={width > 1300 ? FirstImageWEBP : FirstImageWEBPMobi} type="image/webp" />
                    <source srcSet={width > 1300 ? FirstImageJPG : FirstImageJPGMobi} type="image/jpg" />
                    <img src={width > 1300 ? FirstImageJPG : FirstImageJPGMobi} style={{width: "100vw", height: "50vh", objectFit: "cover"}} alt="DinnerHeaderImage" />
                </picture>
                <Typography align="center" style={{position: "absolute", color: "white", fontFamily: "Montserrat", fontSize: "40px", fontWeight: "bold"}}>DINNER MENU</Typography>
            </div>



            {/* Food Category Bar Section */}
            {width > 1300 &&
                <div style={{backgroundColor: "rgba(43, 142, 3, 0.6)", width: "100vw", height: "7vh", display: "flex", alignItems: "center", paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%"}}> 
                    <Button href="#Appetizers" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Appetizers</Button>
                    <Button href="#Pho" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Pho</Button>
                    <Button href="#OtherSoups" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Other Soups</Button>
                    <Button href="#ThaiStirFry" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px","&:hover": {color: "white", backgroundColor: "transparent"}}}>Thai Stir Fry</Button>
                    <Button href="#ThaiCurry" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px","&:hover": {color: "white", backgroundColor: "transparent"}}}>Thai Curry</Button>
                    <Button href="#FromTheWok" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px","&:hover": {color: "white", backgroundColor: "transparent"}}}>From the WOK</Button>
                    <Button href="#VermicelliBowl" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Vermicelli Bowl</Button>
                    <Button href="#FriedRice" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Fried Rice</Button>
                    <Button href="#RicePlate" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Rice Plate</Button>
                    <Button href="#SideOrders" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Sides</Button>
                    <Button href="#Dessert" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1600 ? "16px" : width > 1450 ? "14px" : "12px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Dessert</Button>
                </div>
            }



            {/* Legend Section */}
            {width > 1300 ? 
                <Grid container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "3%" : width > 900 ? "4%" : width > 600 ? "6%" : "8%"}}>
                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Spice&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiChiliPepper style={{color: "#E91919"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Spicy</Typography>
                    </Grid>

                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Vegetarian&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <FaLeaf style={{color: "#2B8E03"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Vegetarian</Typography>
                    </Grid>

                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Chef's Special&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <IoRestaurantSharp style={{color: "#AAAAAA"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Chef's Special</Typography>
                    </Grid>

                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Peanuts&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiPeanut style={{color: "#C97E27"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Peanuts</Typography>
                    </Grid>

                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Gluten Free Option&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <img src={GlutenFree} alt="GlutenFreeImage" style={{height: "1em", width: "1em"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Gluten Free Option</Typography>
                    </Grid>
                </Grid>
            :
                <Grid container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "2%" : width > 900 ? "3%" : width > 600 ? "4%" : "6%"}}>
                    <Grid item xs={width > 1300 ? 12 : 6} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Spice&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiChiliPepper style={{color: "#E91919"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Spicy</Typography>
                        <br />
                        <br />
                        <br />
                    </Grid>
                    {width <= 1300 &&
                        <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                            <IconButton onClick={handleClick}>
                                <Typography sx={{fontFamily: "Montserrat", color: "black"}}>Filters&nbsp;&nbsp;</Typography>
                                <CgMenuGridO style={{color: "black"}} />
                            </IconButton>
                            <Menu anchorEl={menuElement} open={open} onClose={handleClose}>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#Appetizers" style={{textDecoration: "none", color: "black"}}>Appetizers</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#Pho" style={{textDecoration: "none", color: "black"}}>Pho</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#OtherSoups" style={{textDecoration: "none", color: "black"}}>Other Soups</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#ThaiStirFry" style={{textDecoration: "none", color: "black"}}>Thai Stir Fry</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#ThaiCurry" style={{textDecoration: "none", color: "black"}}>Thai Curry</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#FromTheWok" style={{textDecoration: "none", color: "black"}}>From the Wok</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#VermicelliBowl" style={{textDecoration: "none", color: "black"}}>Vermicelli Bowls</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#FriedRice" style={{textDecoration: "none", color: "black"}}>Fried Rice</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#RicePlate" style={{textDecoration: "none", color: "black"}}>Rice Plates</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#SideOrders" style={{textDecoration: "none", color: "black"}}>Side Orders</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#Dessert" style={{textDecoration: "none", color: "black"}}>Dessert</a></MenuItem>
                            </Menu>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Vegetarian&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <FaLeaf style={{color: "#2B8E03"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Vegetarian</Typography>
                        <br />
                        <br />
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Chef's Special&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <IoRestaurantSharp style={{color: "#AAAAAA"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Chef's Special</Typography>
                        <br />
                        <br />
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Peanuts&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiPeanut style={{color: "#C97E27"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Peanuts</Typography>
                        <br />
                        <br />
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Gluten Free Option&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <img src={GlutenFree} alt="GlutenFreeImage" style={{height: "1em", width: "1em"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Gluten Free Option</Typography>
                        <br />
                        <br />
                        <br />
                    </Grid>
                </Grid>
            }



            {/* Appetizer Section */}
            <Grid id="Appetizers" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "3%" : width > 900 ? "5%" : width > 600 ? "5%" : "8%"}}>
                <Grid  item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>APPETIZERS</Typography>
                </Grid>

                <ItemCard itemArray={Appetizers} width={width} />
            </Grid>



            {/* Soup Appetizer Section */}
            <Grid id="SoupAppetizer" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>SOUP APPETIZER</Typography>
                </Grid>

                <ItemCard itemArray={SoupAppetizer} width={width} />
            </Grid>



            {/* Pho Section */}
            <Grid id="Pho" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>PHO</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: width > 1300 ? "1%" : width > 900 ? "2%" : width > 600 ? "3%" : "4%", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Medium: $15 | Large $17.49</Typography>
                </Grid>

                <ItemCard itemArray={Pho} width={width} />
            </Grid>



            {/* Other Soups Section */}
            <Grid id="OtherSoups" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>OTHER SOUPS</Typography>
                </Grid>

                <ItemCard itemArray={OtherSoups} width={width} />
            </Grid>



            {/* Thai Stir Fry Section */}
            <Grid id="ThaiStirFry" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>THAI STIR FRY</Typography>
                </Grid>

                <ItemCard itemArray={ThaiStirFry} width={width} />
            </Grid>



            {/* Thai Curry Section */}
            <Grid id="ThaiCurry" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>THAI CURRY</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: "10px" }}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Classic Thai coconut curry served with | Steam Rice (included) | Coconut Rice: $2.50 | Sticky Rice $3.50 |</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: "5px", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Chicken, Mixed Veggies, Tofu $16.49 | Beef $17.49 | Shrimp $18.49</Typography>
                </Grid>


                <ItemCard itemArray={ThaiCurry} width={width} />
            </Grid>



            {/* From the Wok Section */}
            <Grid id="FromTheWok" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>FROM THE WOK</Typography>
                </Grid>

                <ItemCard itemArray={FromTheWok} width={width} />
            </Grid>



            {/* Fried Rice Section */}
            <Grid id="FriedRice" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>FRIED RICE</Typography>
                </Grid>

                <ItemCard itemArray={FriedRice} width={width} />
            </Grid>



            {/* Vermicelli Bowl Section */}
            <Grid id="VermicelliBowl" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>VERMICELLI BOWL</Typography>
                </Grid>

                <ItemCard itemArray={VermicelliBowl} width={width} />
            </Grid>



            {/* Rice Plate Section */}
            <Grid id="RicePlate" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "4%" : width > 900 ? "6%" : width > 600 ? "8%" : "12%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>RICE PLATE</Typography>
                </Grid>

                <ItemCard itemArray={RicePlate} width={width} />
            </Grid>



            {/* Side Orders Section */}
            <Grid id="SideOrders" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "4%" : width > 900 ? "6%" : width > 600 ? "8%" : "12%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>SIDE ORDERS</Typography>
                </Grid>

                <ItemCard itemArray={SideOrders} width={width} />
            </Grid>



            {/* Dessert Section */}
            <Grid id="Dessert" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "4%" : width > 900 ? "6%" : width > 600 ? "8%" : "12%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>DESSERT</Typography>
                </Grid>

                <ItemCard itemArray={Dessert} width={width} />
            </Grid>



            {/* Image top line section */}
            <Grid container sx={{marginTop: width > 1300 ? "6%" : width > 900 ? "8%" : width > 600 ? "10%" : "16%", marginBottom: "10px"}}>
                {width > 700 &&
                    <>
                        <Grid item xs={3}>
                            <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                        </Grid>
                        <Grid item xs={3}>
                            <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                        </Grid>
                    </>
                }
                <Grid item xs={width > 700 ? 3 : 6}>
                    <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                </Grid>
                <Grid item xs={width > 700 ? 3 : 6}>
                    <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                </Grid>
            </Grid>



            {/* Image Section */}
            <Grid container>
                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingRight: width > 700 ? "" : "2px", paddingBottom: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 106</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>Crab Rangoon</Typography>
                    </div>
                    <picture>
                        <source srcSet={DinnerImage1WEBP} type="image/webp" />
                        <source srcSet={DinnerImage1PNG} type="image/png" />
                        <img src={DinnerImage1PNG} alt="DinnerImage1" height="100%" width="100%" />
                    </picture>
                </Grid>

                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingLeft: width > 700 ? "" : "2px", paddingBottom: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 507</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>BBQ Shrimp with Lemongrass Chicken Breast</Typography>
                    </div>
                    <picture>
                        <source srcSet={DinnerImage2WEBP} type="image/webp" />
                        <source srcSet={DinnerImage2PNG} type="image/png" />
                        <img src={DinnerImage2PNG} alt="DinnerImage2" height="100%" width="100%" />
                    </picture>
                </Grid>

                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingRight: width > 700 ? "" : "2px", paddingTop: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 250</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>Wonton Noodle Soup</Typography>
                    </div>
                    <picture>
                        <source srcSet={DinnerImage3WEBP} type="image/webp" />
                        <source srcSet={DinnerImage3PNG} type="image/png" />
                        <img src={DinnerImage3PNG} alt="DinnerImage3" height="100%" width="100%" />
                    </picture>
                </Grid>

                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingLeft: width > 700 ? "" : "2px", paddingTop: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 319</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>Pad See Ew</Typography>
                    </div>
                    <picture>
                        <source srcSet={DinnerImage4WEBP} type="image/webp" />
                        <source srcSet={DinnerImage4PNG} type="image/png" />
                        <img src={DinnerImage4PNG} alt="DinnerImage4" height="100%" width="100%" />
                    </picture>
                </Grid>
            </Grid>



            {/* Arrow to scroll up */}
            <Scroll 
                render={({y}) => (
                    <Fade in={y > 400} timeout={1000}>
                        <div style={{position: "fixed", bottom: "5%", left: "50%", transform: "translate(-50%, 0)"}}>
                            <IconButton onClick={() => {window.scrollTo(0, 0);}} sx={{boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.5)", transition: "0.2s", backgroundColor: "#303030", "&:hover": {backgroundColor: "black", transform: "translateY(-4px)"}}}>
                                <KeyboardArrowUpIcon sx={{color: "white"}} />
                            </IconButton>
                        </div>
                    </Fade>
                )}
            />



            {/* Footer and NavBar */}
            <Footer />
            <NavBar />
        </div>
    );
};

export default DinnerPage;


const ItemCard = ({ itemArray, width }) => {

    return (
        <>
            {itemArray.map((item, ID) => (
                <React.Fragment key={ID}>
                    {width > 1300 && ID % 3 === 1 && <Grid item xs={1.05} />}
                    
                    <Grid key={ID} item xs={width > 1300 ? 3.3 : width > 900 ? 5.5 : 12} sx={{marginTop: "1%", borderBottom: "1px solid #D7DEE6", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : "5%", position: "relative"}}>
                        {item.number === "" ? 
                            <Typography variant="h6" sx={{fontFamily: "Montserrat", fontWeight: 800}}>{item.name}</Typography>
                        :
                            <Typography variant="h6" sx={{fontFamily: "Montserrat", fontWeight: 800}}>{item.number} - {item.name}</Typography>
                        }
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", marginTop: "2%"}}>{item.desc}</Typography>

                        {item.extraInfo !== "" && <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", marginTop: "2%"}}>{item.extraInfo}</Typography>}

                        {item.extraInfo2 !== "" && <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", marginTop: "2%"}}>{item.extraInfo2}</Typography>}
                        
                        <p style={{ fontFamily: "Montserrat", position: "absolute", bottom: "5px", right: "5px" }}>{item.price}</p>
                        
                        <br />
                        {item.spice === true && 
                            <>
                                <Tooltip title="Spice" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <GiChiliPepper style={{color: "#E91919"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.veg === true && 
                            <>
                                <Tooltip title="Vegetarian" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <FaLeaf style={{color: "#2B8E03"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.chefSpecial === true && 
                            <>
                                <Tooltip title="Chef's Special" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <IoRestaurantSharp style={{color: "#AAAAAA"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.peanuts === true && 
                            <>
                                <Tooltip title="Peanuts" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <GiPeanut style={{color: "#C97E27"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.gluten === true && 
                            <>
                                <Tooltip title="Gluten Free Option" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <img src={GlutenFree} alt="GlutenFreeImage" style={{height: "1em", width: "1em"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {(item.spice === true || item.veg === true || item.chefSpecial === true || item.peanuts === true || item.gluten === true) && <><br /><br /></>}
                    </Grid>
                    {width > 1300 && ID % 3 === 1 && <Grid item xs={1.05} />}
                    {width <= 1300 && width > 900 && ID % 2 === 0 && <Grid item xs={1} />}
                </React.Fragment>
            ))}
        </>
    )
}