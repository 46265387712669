import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";


const NotFound = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "80px", paddingLeft: "20px", paddingRight: "20px"}}>
            <Typography variant="h1" align="center" sx={{fontFamily: "Montserrat", fontWeight: "bold"}}>404</Typography>
            <Typography variant="h5" align="center" sx={{fontFamily: "Montserrat", marginTop: "30px"}}>Sorry, this page does not exist!</Typography>
            <Typography variant="h6" align="center" sx={{fontFamily: "Montserrat", marginTop: "10px"}}>- The Lotus Team</Typography>
            <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
                <Button component={Link} to="/" sx={{fontFamily: "Montserrat", backgroundColor: "#2B8E03", color: "white", transition: "0.3s", "&:hover": {backgroundColor: "#298105"}}}>Back to Home Page</Button>
            </div>
        </div>
    );
};

export default NotFound;