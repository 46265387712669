import { Grid, Typography, Tooltip } from "@mui/material";
import { useWidth } from "../WidthContext";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import styled from "styled-components";

const Footer = () => {
    const { width } = useWidth();

    return (
        <Grid container sx={{ backgroundColor: "#2F2F2F", display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} sx={{marginTop: "60px", marginBottom: "60px"}}>
                {/* <br />
                <Typography variant="subtitle1" align="center" sx={{ fontFamily: "Montserrat", fontWeight: 600, marginTop: width > 1500 ? "2%" : width > 700 ? "3%" : "6%", color: "white" }}>LOTUS&nbsp;</Typography>
                <Typography variant="subtitle2" align="center" sx={{ fontFamily: "Montserrat", fontWeight: 400, marginTop: width > 700 ? "1%" : "2%", color: "white" }}>
                    280 Mud St W, Stoney Creek, ON L8J 3Z6
                    <br />
                    (905) - 561 - 1888
                </Typography>
                <br />
                <br />
                <br />
                <br /> */}

                <Typography variant="subtitle1" align="center" sx={{ fontFamily: "Montserrat", fontWeight: 600, color: "white", marginBottom: width > 900 ? "1%" : width > 600 ? "2%" : "4%" }}>LOTUS&nbsp;</Typography>
                <Typography align="center" variant={width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white", marginBottom: width > 900 ? "" : "1%"}}>
                    280 Mud St W, Stoney Creek, ON L8J 3Z6
                </Typography>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <PhoneAnchor href="tel:905-561-1888">
                        <Typography variant={width > 1100 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat"}}>
                        (905) - 561 - 1888
                        </Typography>
                    </PhoneAnchor>
                </div>

                <Grid container sx={{display: "flex", justifyContent: "center", marginTop: width > 700 ? "2%" : "8%", marginBottom: width > 700 ? "2%" : "8%"}}>
                        <Tooltip title="Facebook" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <StyledAnchor
                                target="_blank"
                                href="https://www.facebook.com/lotusthainviet/"
                                rel="noreferrer"
                            >
                                <FacebookIcon
                                    style={{
                                        fontSize: "35px",
                                        color: "white",
                                    }}
                                />
                            </StyledAnchor>
                        </Tooltip>
                        &nbsp;&nbsp;{width > 1500 && <>&nbsp;&nbsp;</>}{width > 700 && <>&nbsp;&nbsp;&nbsp;</>}
                        <Tooltip title="Instagram" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <StyledAnchor
                                target="_blank"
                                href="https://www.instagram.com/lotus_cuisine/"
                                rel="noreferrer"
                            >
                                <InstagramIcon
                                    style={{
                                        fontSize: "35px",
                                        color: "white",
                                    }}
                                />
                            </StyledAnchor>
                        </Tooltip>
                </Grid>
                <Typography
                    variant={width > 1100 ? "subtitle1" : "subtitle2"}
                    align="center"
                    sx={{
                        fontFamily: "Montserrat",
                        color: "white",
                        // marginTop: width > 1500 ? "2%" : width > 700 ? "3%" : "5%",
                        // marginBottom: width > 1500 ? "2%" : width > 700 ? "4%" : "8%",
                        paddingLeft: "8%",
                        paddingRight: "8%"
                    }}
                >
                    Copyright © 2022 Lotus Thai & Vietnamese cuisine
                    - All Rights Reserved.
                </Typography>
                {/* <br /> */}
            </Grid>
        </Grid>
    )
};

const StyledAnchor = styled.a`
    display: block;
    transition: transform 0.2s;
    &:hover {
        transform: translateY(-4px);
    }
`;

const PhoneAnchor = styled.a`
    text-decoration: none;
    color: white;
    &: hover {
        text-decoration: underline;
    }
`;

export default Footer;
