import React from "react";
import { Button, Card, Dialog, Grid, CardContent, Typography, IconButton, Fade } from "@mui/material";
import { useWidth } from "../WidthContext";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import CloseIcon from '@mui/icons-material/Close';
import { Scroll } from "react-fns";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Image1PNG from "../images/GalleryPageImages/Image1.png";
import Image2PNG from "../images/GalleryPageImages/Image2.png";
import Image3PNG from "../images/GalleryPageImages/Image3.png";
import Image4PNG from "../images/GalleryPageImages/Image4.png";
import Image5PNG from "../images/GalleryPageImages/Image5.png";
import Image6PNG from "../images/GalleryPageImages/Image6.png";
import Image7PNG from "../images/GalleryPageImages/Image7.png";
import Image8PNG from "../images/GalleryPageImages/Image8.png";
import Image9PNG from "../images/GalleryPageImages/Image9.png";
import Image10PNG from "../images/GalleryPageImages/Image10.png";
import Image11PNG from "../images/GalleryPageImages/Image11.png";
import Image12PNG from "../images/GalleryPageImages/Image12.png";
import Image13PNG from "../images/GalleryPageImages/Image13.png";
import Image14PNG from "../images/GalleryPageImages/Image14.png";
import Image15PNG from "../images/GalleryPageImages/Image15.png";
import Image16PNG from "../images/GalleryPageImages/Image16.png";
import Image17PNG from "../images/GalleryPageImages/Image17.png";
import Image18PNG from "../images/GalleryPageImages/Image18.png";
import Image19PNG from "../images/GalleryPageImages/Image19.png";
import Image20PNG from "../images/GalleryPageImages/Image20.png";
import Image21PNG from "../images/GalleryPageImages/Image21.png";
import Image22PNG from "../images/GalleryPageImages/Image22.png";
import Image23PNG from "../images/GalleryPageImages/Image23.png";
import Image24PNG from "../images/GalleryPageImages/Image24.png";
import Image1WEBP from "../images/GalleryPageImages/Image1.webp";
import Image2WEBP from "../images/GalleryPageImages/Image2.webp";
import Image3WEBP from "../images/GalleryPageImages/Image3.webp";
import Image4WEBP from "../images/GalleryPageImages/Image4.webp";
import Image5WEBP from "../images/GalleryPageImages/Image5.webp";
import Image6WEBP from "../images/GalleryPageImages/Image6.webp";
import Image7WEBP from "../images/GalleryPageImages/Image7.webp";
import Image8WEBP from "../images/GalleryPageImages/Image8.webp";
import Image9WEBP from "../images/GalleryPageImages/Image9.webp";
import Image10WEBP from "../images/GalleryPageImages/Image10.webp";
import Image11WEBP from "../images/GalleryPageImages/Image11.webp";
import Image12WEBP from "../images/GalleryPageImages/Image12.webp";
import Image13WEBP from "../images/GalleryPageImages/Image13.webp";
import Image14WEBP from "../images/GalleryPageImages/Image14.webp";
import Image15WEBP from "../images/GalleryPageImages/Image15.webp";
import Image16WEBP from "../images/GalleryPageImages/Image16.webp";
import Image17WEBP from "../images/GalleryPageImages/Image17.webp";
import Image18WEBP from "../images/GalleryPageImages/Image18.webp";
import Image19WEBP from "../images/GalleryPageImages/Image19.webp";
import Image20WEBP from "../images/GalleryPageImages/Image20.webp";
import Image21WEBP from "../images/GalleryPageImages/Image21.webp";
import Image22WEBP from "../images/GalleryPageImages/Image22.webp";
import Image23WEBP from "../images/GalleryPageImages/Image23.webp";
import Image24WEBP from "../images/GalleryPageImages/Image24.webp";


const GalleryPage = () => {
    const { width } = useWidth();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>          
            <div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden", marginTop: width > 600 ? "118px" : "106px"}}>
                {/* Gallery Header Section */}
                <div style={{display: "flex", flexWrap: "row", justifyContent: "center", marginBottom: width > 600 ? "2vh" : "1vh"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Patrick Hand", fontWeight: "bold", color: "#F63788"}}>
                        <u style={{textUnderlineOffset: "5px"}}>
                            GALLERY
                        </u>
                    </Typography>
                </div>



                {/* Click on image description section */}
                <div style={{display: "flex", flexWrap: "row", justifyContent: "center", marginBottom: width > 600 ? "54px" : "48px"}}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Please click on the image for more details</Typography>
                </div>

                <Grid container sx={{paddingLeft: width > 900 ? "10%" : "5px", paddingRight: width > 900 ? "10%" : "5px", display: "flex", justifyContent: "center"}}>
                    {imageArray.map((instance, ID) => (
                        <CardComponent key={ID} pictureInformation={instance} width={width} />
                    ))}
                </Grid>


                
                {/* Spacing */}
                <br />
                <br />
                <br />
                <br />
                <br />
                {width > 700 &&
                    <>
                        <br />
                        <br />
                    </>
                }



                {/* Arrow to scroll up */}
                <Scroll 
                    render={({y}) => (
                        <Fade in={y > 300} timeout={1000}>
                            <div style={{position: "fixed", bottom: "5%", left: "50%", transform: "translate(-50%, 0)"}}>
                                <IconButton onClick={() => {window.scrollTo(0, 0);}} sx={{boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.5)", transition: "0.2s", backgroundColor: "#303030", "&:hover": {backgroundColor: "black", transform: "translateY(-4px)"}}}>
                                    <KeyboardArrowUpIcon sx={{color: "white"}} />
                                </IconButton>
                            </div>
                        </Fade>
                    )}
                />



                {/* Footer and NavBar sections */}
                <Footer />
                <NavBar />
            </div>

        </>
    );
};

export default GalleryPage;


// Card Component
const CardComponent = ({pictureInformation, width}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Grid item xs={width > 700 ? 4 : 6} sx={{padding: "5px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                <Button onClick={() => {setOpen(true);}} sx={{padding: "0", color: "white"}}>
                    <picture>
                        <source srcSet={pictureInformation.nameWEBP} type="image/webp" />
                        <source srcSet={pictureInformation.namePNG} type="image/png" />
                        <img src={pictureInformation.namePNG} alt={pictureInformation.alt} height="100%" width="100%" style={{display: "block"}} />
                    </picture>
                </Button>
            </Grid>

            <Dialog open={open} onClose={() => {setOpen(false);}} disableScrollLock={true}>
                <Card>
                    <CardContent>
                        <Grid container sx={{borderBottom: "1px solid #D7DEE6"}}>
                            <Grid item xs={10} sx={{display: "flex", alignItems: "center"}}>
                                <Typography variant="h6" sx={{fontFamily: "Source Sans Pro", color: "black"}}>
                                    Photo Details
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography> 
                            </Grid>
                            <Grid item xs={2} sx={{display: "flex", justifyContent: "flex-end"}}>
                                <IconButton onClick={() => {setOpen(false);}}>
                                    <CloseIcon sx={{color: "black"}} />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro", fontWeight: "bold"}}>{pictureInformation.info1}</Typography>
                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro"}}>{pictureInformation.info2}</Typography>
                        {pictureInformation.info3 !== "" && <hr style={{border: "1px solid #D7DEE6"}} />}
                        
                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro", fontWeight: "bold"}}>{pictureInformation.info3}</Typography>
                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro"}}>{pictureInformation.info4}</Typography>
                        {pictureInformation.info5 !== "" && <hr style={{border: "1px solid #D7DEE6"}} />}

                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro", fontWeight: "bold"}}>{pictureInformation.info5}</Typography>
                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro"}}>{pictureInformation.info6}</Typography>
                        {pictureInformation.info7 !== "" && <hr style={{border: "1px solid #D7DEE6"}} />}

                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro", fontWeight: "bold"}}>{pictureInformation.info7}</Typography>
                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro"}}>{pictureInformation.info8}</Typography>
                        <Typography variant="h6" sx={{fontFamily: "Source Sans Pro", fontWeight: "bold"}}>{pictureInformation.extraInfo}</Typography>
                    </CardContent>
                </Card>
            </Dialog>
        </>
    )
}


// Image Array
const imageArray = [
    {namePNG: Image1PNG, nameWEBP: Image1WEBP, alt: "Image1", info1: "No. 401", info2: "Udon Noodle in Black Bean Sauce", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image2PNG, nameWEBP: Image2WEBP, alt: "Image2", info1: "No. 455", info2: "Pad Kra Pao (Top Right)", info3: "No. 103", info4: "Fresh Shrimp Rolls (Middle Left)", info5: "No. 501", info6: "BBQ Pork & Spring Roll Vermicelli Bowl (Bottom Right)", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image3PNG, nameWEBP: Image3WEBP, alt: "Image3", info1: "No. 508", info2: "Vegan Vermicelli Bowl", info3: "", info4: "", info5: "", info7: "", info8: "", extraInfo: ""}, 

    {namePNG: Image4PNG, nameWEBP: Image4WEBP, alt: "Image4", info1: "No. 304", info2: "Bami Goreng", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image5PNG, nameWEBP: Image5WEBP, alt: "Image5", info1: "No. 106", info2: "Crab Rangoon", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image6PNG, nameWEBP: Image6WEBP, alt: "Image6", info1: "No. 305", info2: "Crispy Tamarind Fish", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""},

    {name: Image7PNG, nameWEBP: Image7WEBP, alt: "Image7", info1: "No. 202", info2: "Rare Lean Beef Noodle Soup", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {name: Image8PNG, nameWEBP: Image8WEBP, alt: "Image8", info1: "No. 101", info2: "Spring Rolls (Top Left, Bottom Right)", info3: "No. 106", info4: "Crab Rangoon (Bottom Left)", info5: "No. 107", info6: "Chicken Satays (Middle Right)", info7: "No. 109", info8: "Coconut Butterfly Shrimp (Top Middle)", extraInfo: ""}, 
    {name: Image9PNG, nameWEBP: Image9WEBP, alt: "Image9", info1: "No. 452", info2: "Thai Spicy Fried Rice", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 

    {namePNG: Image10PNG, nameWEBP: Image10WEBP, alt: "Image10", info1: "No. 602", info2: "Sunnyside up Egg, BBQ Pork", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image11PNG, nameWEBP: Image11WEBP, alt: "Image11", info1: "No. 103", info2: "Fresh Shrimp Rolls", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image12PNG, nameWEBP: Image12WEBP, alt: "Image12", info1: "No. 330", info2: "Green Curry", info3: "", info4: "", info5: "", info7: "", info8: "", extraInfo: ""}, 

    {namePNG: Image13PNG, nameWEBP: Image13WEBP, alt: "Image13", info1: "No. 320", info2: "Black Pepper Beef Stir Fry", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image14PNG, nameWEBP: Image14WEBP, alt: "Image14", info1: "No. 112", info2: "Tom Yum Soup", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image15PNG, nameWEBP: Image15WEBP, alt: "Image15", info1: "No. 211", info2: "Lotus Special Pho Sate", info3: "", info4: "", info5: "", info7: "", info8: "", extraInfo: ""}, 

    {namePNG: Image16PNG, nameWEBP: Image16WEBP, alt: "Image16", info1: "No. 250", info2: "Wonton Noodle Soup", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image17PNG, nameWEBP: Image17WEBP, alt: "Image17", info1: "No. 602", info2: "Sunnyside up Egg, BBQ Pork", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image18PNG, nameWEBP: Image18WEBP, alt: "Image18", info1: "No. 251", info2: "Hue Style Beef Noodle Soup", info3: "", info4: "", info5: "", info7: "", info8: "", extraInfo: ""}, 

    {namePNG: Image19PNG, nameWEBP: Image19WEBP, alt: "Image19", info1: "No. 306", info2: "Crispy Mango Fish", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image20PNG, nameWEBP: Image20WEBP, alt: "Image20", info1: "No. 312", info2: "Drunken Noodle Stir Fry", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image21PNG, nameWEBP: Image21WEBP, alt: "Image21", info1: "No. 304", info2: "Bami Goreng", info3: "", info4: "", info5: "", info7: "", info8: "", extraInfo: ""}, 

    {namePNG: Image22PNG, nameWEBP: Image22WEBP, alt: "Image22", info1: "No. 402", info2: "Black Bean Sauce Stir Fry", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""},
    {namePNG: Image23PNG, nameWEBP: Image23WEBP, alt: "Image22", info1: "No. 311", info2: "Singapore Noodles", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""}, 
    {namePNG: Image24PNG, nameWEBP: Image24WEBP, alt: "Image24", info1: "No. 402", info2: "Black Bean Sauce Stir Fry", info3: "", info4: "", info5: "", info6: "", info7: "", info8: "", extraInfo: ""},
];