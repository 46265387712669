import styled from "styled-components";
import { AppBar, Box, Button, IconButton, MenuItem, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Full Screen Styled Components
export const StyledBox = styled(Box)`
    && {
        flex-grow: 1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
`;

export const BackgroundAppBar = styled(AppBar)`
    && {
        background-color: black;
        opacity: 70%;
    }
`;

export const InnerAppBar = styled(AppBar)`
    && {
        box-shadow: none;
    }
`;

export const OuterButtonDiv = styled.div`
    width: 90%;
    display: flex;
    justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
    && {
        padding-right: 1%;
        padding-left: 1%;
        font-family: Source Sans Pro;
        font-size: ${({width}) => width > 1400 ? "15px" : "14px"};
        margin-right: ${({width, id, buttonarraylength}) => id === buttonarraylength - 1 ? width > 1300 ? "1%": "0%" : "1%"};
        color: ${({active}) => active === "true" ? "#39db51" : "white"};
        border-bottom: ${({active}) => active === "true" ? "2px solid #39db51" : ""};
        border-radius: 0;
        &:hover {
            border-bottom: ${({active}) => active === "false" && "2px solid white"};
            border-radius: ${({active}) => active === "false" && "0"};
        }
        font-weight: bold;
    }
`;



// Mobile Styled Components
export const StyledTypography = styled(Typography)`
    && {
        position: absolute;
        left: 0;
        color: white;
        margin-left: 5%;
        font-size: 30px;
        font-family: Source Sans Pro;
    }
`;

export const StyledIconButton = styled(IconButton)`
    && {
        position: absolute;
        right: 0;
        margin-right: 3%;
    }
`;

export const StyledMenuIcon = styled(MenuIcon)`
    && {
        font-size: 40px;
        color: white;
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    && {
        background-color: ${({active}) => active === "true" && "#B2F1BB"};
        font-family: Source Sans Pro;
        font-size: 20px;
    }
`;
