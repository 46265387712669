import React from "react";
import NavBar from "../Components/NavBar";
import GoogleMaps from "../Components/GoogleMaps";
import Footer from "../Components/Footer";
import FirstImageJPG from "../images/ContactPageImages/FirstImage.jpg";
import FirstImageWEBP from "../images/ContactPageImages/FirstImage.webp";
import { useWidth } from "../WidthContext";
import { Typography, Grid, Table, TableBody, TableRow, Button, } from "@mui/material";
import { OuterContainer, ContactContainer, StyledImage, StyledParagraph, FullGridContainer, PhoneAnchor, StyledIconButtonOne, 
    StyledIconButtonSecond, StyledInstagramIcon, StyledFacebookIcon, FullStyledTable, FullStyledHeaderTableCell, FullStyledSecondaryTableCell,
    FullStyledLocationTypography, FullStyledLocationInfoTypography, FullDirectionsButton, FullHoursHeaderTypography, FullHoursInformationTypography,
    FullHoursInformationTypographyShifted, FullExtraInfoHeaderTypography, FullExtraInfoInformationTypography, FullExtraInfoInformationTypographyShifted,
    MobileContactContainer, MobileGridItem, MobileContactTypography, MobilePhoneNumberTypography, MobileIconButton, MobileInstagramIcon,
    MobileFacebookIcon, MobileLocationTypography, MobileLocationInfoTypograpy, MobileDirectionsButton, MobileHoursTypography, MobileStyledTable,
    MobileStyledTableCell, MobileInformationTypography, MobileInformationTypographyShifted, MobileExtraInfoHeaderTypography, MobileExtraInfoTypography,
    MobileExtraInfoLunchTypography, MobileExtraInfoTypographyShifted, MobileExtraInfoTypographyDinner } from "./ContactPageStyles";

const ContactPage = () => {
    const { width } = useWidth();

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <OuterContainer>
                {/* Contact Us Header Section */}
                <ContactContainer>
                    <picture style={{height: "50vh"}}>
                        <source srcSet={FirstImageWEBP} type="image/webp" />
                        <source srcSet={FirstImageJPG} type="image/jpg" />
                        <StyledImage src={FirstImageJPG} alt="ContactUsImage" />
                    </picture>
                    <StyledParagraph>CONTACT US</StyledParagraph>
                </ContactContainer>


                {/* <br />
                <br />
                <br />
                <hr style={{border: "1px solid #D7DEE6"}} /> */}

                {/* Full Screen Information Section */}
                {width > 1300 ?
                    <FullGridContainer container columnSpacing={width > 1400 ? 26 : 22}>
                        <Grid item>
                            <Typography variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, marginLeft: width > 1400 ? "-38%" : "-43%"}}>CONTACT DETAILS</Typography>
                            <div style={{display: "flex", marginLeft: width > 1400 ? "-38%" : "-43%", marginTop: "2%"}}>
                                <PhoneAnchor href="tel:905-561-1888">
                                    <Typography variant="h6" sx={{fontFamily: "Montserrat", fontWeight: 400}}>(905) - 561 - 1888</Typography>
                                </PhoneAnchor>
                            </div>
                            <StyledIconButtonOne href="https://www.instagram.com/lotus_cuisine/" target="_blank" width={width}>
                                <StyledInstagramIcon />
                            </StyledIconButtonOne>
                            &nbsp;&nbsp;&nbsp;
                            <StyledIconButtonSecond href="https://www.facebook.com/lotusthainviet/" target="_blank" width={width}>
                                <StyledFacebookIcon />
                            </StyledIconButtonSecond>
                            <Typography variant="h6" sx={{fontFamily: "Montserrat", marginTop: "4%", marginLeft: width > 1400 ? "-38%" : "-43%"}}>Please let us know how we did!</Typography>
                            <Button variant="outlined" href="https://search.google.com/local/writereview?placeid=ChIJc749zMiZLIgRvH4INGh-R6s" target="_blank" sx={{color: "#2B8E03", borderColor: "#2B8E03", "&:hover": {borderColor: "#2B8E03"}, fontFamily: "Montserrat", fontWeight: 800, marginTop: "4%", marginLeft: width > 1400 ? "-38%" : "-43%"}}>Write a review</Button>
                            <br />
                            <br />
                            <br />
                            <br />
                        </Grid>
                        <Grid item />
                        <Grid item />
                        <Grid item />
                        <Grid item xs={12} />

                        <Grid item>
                            <FullStyledLocationTypography variant="h4">LOCATION</FullStyledLocationTypography>
                            <FullStyledLocationInfoTypography variant="h6">280 Mud St W, Stoney Creek,</FullStyledLocationInfoTypography>
                            <FullStyledLocationInfoTypography variant="h6">Hamilton, ON L8J 3Z6</FullStyledLocationInfoTypography>
                            <FullDirectionsButton variant="outlined" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Lotus+Thai+%26+Vietnamese+Cuisine/@43.1963928,-79.7960776,17z/data=!3m1!4b1!4m5!3m4!1s0x882c99c8cc3dbe73:0xab477e6834087ebc!8m2!3d43.1963914!4d-79.7938879">Click here for Directions</FullDirectionsButton>
                        </Grid>

                        <Grid item>
                            <FullHoursHeaderTypography variant="h4">HOURS</FullHoursHeaderTypography>
                            <FullStyledTable>
                                <TableBody>
                                    <TableRow>
                                        <FullStyledHeaderTableCell>
                                            <FullHoursInformationTypography variant="h6">Mon&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</FullHoursInformationTypography>
                                        </FullStyledHeaderTableCell>
                                        <FullStyledSecondaryTableCell>
                                            <FullHoursInformationTypography variant="h6">CLOSED</FullHoursInformationTypography>
                                        </FullStyledSecondaryTableCell>
                                    </TableRow>

                                    {["Tue", "Wed", "Thu", "Fri"].map((day, ID) => (
                                        <TableRow key={ID}>
                                            <FullStyledHeaderTableCell>
                                                <FullHoursInformationTypography variant="h6">{day}</FullHoursInformationTypography>
                                            </FullStyledHeaderTableCell>
                                            <FullStyledSecondaryTableCell>
                                                <FullHoursInformationTypographyShifted variant="h6">11AM - 8PM</FullHoursInformationTypographyShifted>
                                            </FullStyledSecondaryTableCell>
                                        </TableRow>
                                    ))}

                                    {["Sat", "Sun"].map((day, ID) => (
                                        <TableRow key={ID}>
                                            <FullStyledHeaderTableCell>
                                                <FullHoursInformationTypography variant="h6">{day}</FullHoursInformationTypography>
                                            </FullStyledHeaderTableCell>
                                            <FullStyledSecondaryTableCell>
                                                <FullHoursInformationTypography variant="h6">12PM - 8PM</FullHoursInformationTypography>
                                            </FullStyledSecondaryTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </FullStyledTable>
                        </Grid>
                            
                        <Grid item>
                            <FullExtraInfoHeaderTypography variant="h4">EXTRA INFORMATION</FullExtraInfoHeaderTypography>
                            <FullStyledTable>
                                <TableBody>
                                    <TableRow>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypography variant="h6">Lunch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</FullExtraInfoInformationTypography>
                                        </FullStyledHeaderTableCell>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypography variant="h6">Tue - Fri&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</FullExtraInfoInformationTypography>
                                        </FullStyledHeaderTableCell>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypographyShifted variant="h6">11AM - 3PM</FullExtraInfoInformationTypographyShifted>
                                        </FullStyledHeaderTableCell>
                                    </TableRow>

                                    <TableRow>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypography variant="h6">Dinner</FullExtraInfoInformationTypography>
                                        </FullStyledHeaderTableCell>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypography variant="h6">Tue - Fri</FullExtraInfoInformationTypography>
                                        </FullStyledHeaderTableCell>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypography variant="h6">&nbsp;3PM - 8PM</FullExtraInfoInformationTypography>
                                        </FullStyledHeaderTableCell>
                                    </TableRow>

                                    <TableRow>
                                        <FullStyledHeaderTableCell>
                                        </FullStyledHeaderTableCell>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypography variant="h6">Sat - Sun</FullExtraInfoInformationTypography>
                                        </FullStyledHeaderTableCell>
                                        <FullStyledHeaderTableCell>
                                            <FullExtraInfoInformationTypography variant="h6">12PM - 8PM</FullExtraInfoInformationTypography>
                                        </FullStyledHeaderTableCell>
                                    </TableRow>
                                </TableBody>
                            </FullStyledTable>
                        </Grid>
                    </FullGridContainer>
                :
                    // Mobile Information Section
                    <MobileContactContainer container width={width}>
                        <Grid item xs={12}>
                            <MobileContactTypography variant="h6">CONTACT DETAILS</MobileContactTypography>
                            <div style={{display: "flex", marginTop: "1%"}}>
                                <PhoneAnchor href="tel:905-561-1888">
                                    <MobilePhoneNumberTypography variant="subtitle1">(905) - 561 - 1888</MobilePhoneNumberTypography>
                                </PhoneAnchor>
                            </div>
                            <MobileIconButton width={width} href="https://www.instagram.com/lotus_cuisine/" target="_blank">
                                <MobileInstagramIcon />
                            </MobileIconButton>
                            &nbsp;&nbsp;&nbsp;
                            <MobileIconButton width={width} href="https://www.facebook.com/lotusthainviet/" target="_blank">
                                <MobileFacebookIcon />
                            </MobileIconButton>
                            <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", marginTop: width > 600 ? "1%" : "2%"}}>Please let us know how we did!</Typography>
                            <Button variant="outlined" href="https://search.google.com/local/writereview?placeid=ChIJc749zMiZLIgRvH4INGh-R6s" target="_blank" sx={{color: "#2B8E03", borderColor: "#2B8E03", "&:hover": {borderColor: "#2B8E03"}, fontFamily: "Montserrat", fontWeight: 800, marginTop: width > 600 ? "1%" : "2%", fontSize: "12px"}}>Write a review</Button>
                        </Grid>
                        
                        <Grid sx={{ marginTop: width > 1000 ? "3%" : width > 600 ? "5%" : "9%" }}>
                            <div>
                                <MobileLocationTypography variant="h6">LOCATION</MobileLocationTypography>
                                <MobileLocationInfoTypograpy variant="subtitle1">280 Mud St W, Stoney Creek,</MobileLocationInfoTypograpy>
                                <MobileLocationInfoTypograpy variant="subtitle1">Hamilton, ON, L8J 3Z6</MobileLocationInfoTypograpy>
                                <MobileDirectionsButton variant="outlined" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Lotus+Thai+%26+Vietnamese+Cuisine/@43.1963928,-79.7960776,17z/data=!3m1!4b1!4m5!3m4!1s0x882c99c8cc3dbe73:0xab477e6834087ebc!8m2!3d43.1963914!4d-79.7938879">Click here for Directions</MobileDirectionsButton>
                            </div>
                        </Grid>

                        <MobileGridItem item xs={12} width={width}>
                            <div>
                                <MobileHoursTypography variant="h6">HOURS</MobileHoursTypography>
                                <MobileStyledTable>
                                    <TableBody>
                                        <TableRow>
                                            <MobileStyledTableCell width={100}>
                                                <MobileInformationTypography variant="subtitle1">Mon</MobileInformationTypography>
                                            </MobileStyledTableCell>
                                            <MobileStyledTableCell>
                                                <MobileInformationTypography variant="subtitle1">CLOSED</MobileInformationTypography>
                                            </MobileStyledTableCell>
                                        </TableRow>

                                        {["Tue", "Wed", "Thu", "Fri"].map((day, ID) => (
                                            <TableRow key={ID}>
                                                <MobileStyledTableCell width={100}>
                                                    <MobileInformationTypography variant="subtitle1">{day}</MobileInformationTypography>
                                                </MobileStyledTableCell>
                                                <MobileStyledTableCell>
                                                    <MobileInformationTypographyShifted variant="subtitle1">11AM - 8PM</MobileInformationTypographyShifted>
                                                </MobileStyledTableCell>
                                            </TableRow>
                                        ))}

                                        {["Sat", "Sun"].map((day, ID) => (
                                            <TableRow key={ID}>
                                                <MobileStyledTableCell width={100}>
                                                    <MobileInformationTypography variant="subtitle1">{day}</MobileInformationTypography>
                                                </MobileStyledTableCell>
                                                <MobileStyledTableCell>
                                                    <MobileInformationTypography variant="subtitle1">12PM - 8PM</MobileInformationTypography>
                                                </MobileStyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </MobileStyledTable>
                            </div>
                        </MobileGridItem>

                        <MobileGridItem item xs={12} width={width}>
                            <div>
                                <MobileExtraInfoHeaderTypography variant="h6">EXTRA INFORMATION</MobileExtraInfoHeaderTypography>
                                <MobileExtraInfoLunchTypography variant="subtitle1">Lunch</MobileExtraInfoLunchTypography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <MobileStyledTableCell width={100}>
                                                <MobileExtraInfoTypography variant="subtitle1">Tue - Fri</MobileExtraInfoTypography>
                                            </MobileStyledTableCell>
                                            <MobileStyledTableCell>
                                                <MobileExtraInfoTypographyShifted variant="subtitle1">11AM - 3PM</MobileExtraInfoTypographyShifted>
                                            </MobileStyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MobileExtraInfoTypographyDinner variant="subtitle1">Dinner</MobileExtraInfoTypographyDinner>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <MobileStyledTableCell width={100}>
                                                <MobileExtraInfoTypography variant="subtitle1">Tue - Fri</MobileExtraInfoTypography>
                                            </MobileStyledTableCell>
                                            <MobileStyledTableCell>
                                                <MobileExtraInfoTypography variant="subtitle1">&nbsp;3PM - 8PM</MobileExtraInfoTypography>
                                            </MobileStyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <MobileStyledTableCell width={100}>
                                                <MobileExtraInfoTypography variant="subtitle1">Sat - Sun</MobileExtraInfoTypography>
                                            </MobileStyledTableCell>
                                            <MobileStyledTableCell>
                                                <MobileExtraInfoTypography variant="subtitle1">12PM - 8PM</MobileExtraInfoTypography>
                                            </MobileStyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </MobileGridItem>
                    </MobileContactContainer>
                }


                {/* <hr style={{border: "1px solid #D7DEE6"}} />
                <br />
                <br />
                <br /> */}



                {/* Google Maps and Footer Section */}
                <GoogleMaps />
                <Footer />
            </OuterContainer>



            {/* NavBar Section */}
            <NavBar />
        </>
    );
};

export default ContactPage;