export const JasmineStirFry = [
    { number: "1", name: "Thai Sweet and Sour Stir Fry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Shrimp", desc: "Crispy meat in sweet and sour sauce with pineapples, cucumbers, tomatoes, bell peppers and onions", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "" },
    { number: "3", name: "Spicy Eggplant Stir Fry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Stir fried eggplants in Thai spicy sauce with onions, bell peppers and fresh basil leaves", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "" },
    { number: "4", name: "Fried Rice", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Jasmine fried rice with eggs, corn, peas, carrots, and onions", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "" },
    { number: "5", name: "Thai Spicy Coconut Curry Stir Fry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Stir fried with basil leaves, green beans, bamboo shoots, carrots, bell peppers and onions", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "" },
    { number: "6", name: "Mix Vegetable Stir Fry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Stir fried seasonal mix veggies with light soy sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "" },
    { number: "8", name: "BBQ Chicken Rice Plate", extraInfo: "", desc: "With sunnyside up egg, cucumberes, tomatoes and pickled carrots", chicken: "", mixedVeg: "", tofu: "", beef: "", shrimp: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$14.49" },
];

export const ThaiCoconutcurry = [
    { number: "10", name: "Green Curry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Green curry with bamboo shoots, zucchini, bell peppers, onions and basil leaves", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "" },
    { number: "11", name: "Red Curry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Red curry with bamboo shoots, zucchini, bell peppers, onions and basil leaves", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "" },
    { number: "12", name: "Panang Curry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Panang curry with Thai peanut sauce, zucchini, green beans and lime leaves", spice: true, veg: false, chefSpecial: false, peanuts: true, gluten: true, price: "+$0.50" },
];

export const NoodleStirFry = [
    { number: "15", name: "Fresh Lemongrass Stir Fry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Stir fried with mix veggies served over warm vermicelli noodles", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "" },
    { number: "16", name: "Lotus Special Pad Thai", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Rice noodle with eggs, beansprouts, bell peppers, carrots and onions", spice: true, veg: false, chefSpecial: false, peanuts: true, gluten: false, price: "" },
    { number: "17", name: "Traditional Authentic Pad Thai", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Rice noodle with eggs, beansprouts, green and white onions", spice: false, veg: false, chefSpecial: false, peanuts: true, gluten: true, price: "" },
    { number: "18", name: "Drunken Noodle Stir Fry", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Stir fried rice noodle with eggs, onions, carrots, bell peppers, basil leaves and fresh chilies", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "" },
    { number: "19", name: "Egg Noodle Stir Fry (Chow Mein)", extraInfo: "Please choose from: Chicken, Mixed Veggies, Tofu, Beef, Shrimp", desc: "Stir fried egg noodle with mix veggies in light soy sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "" },
    { number: "20", name: "BBQ Pork or Chicken & Spring Roll Vermicelli Bowl", extraInfo: "", desc: "With beansprouts, lettuce, cucumbers and pickled carrots", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$14.49" },
];

export const NoodleSoup = [
    { number: "21", name: "Rare Beef Noodle Soup", extraInfo: "", desc: "Pho broth with sliced rare lean beef in rice noodle", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "" },
    { number: "22", name: "Chicken Beef Noodle Soup", extraInfo: "", desc: "Pho broth with sliced chicken breast meat in rice noodle", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "" },
    { number: "23", name: "Mix Vegetable Noodle Soup", extraInfo: "", desc: "Chicken broth with seasonal mix veggies in rice noodles", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "" },
];