import React from "react";
import { Toolbar, Fade, Menu, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/NavBar/logo.png";
import { useWidth } from "../WidthContext";
import { StyledBox, BackgroundAppBar, InnerAppBar, OuterButtonDiv, StyledButton, StyledTypography, 
    StyledIconButton, StyledMenuIcon, StyledMenuItem } from "./NavBarStyles";

const NavBar = () => {
    const { width } = useWidth();
    const navigate = useNavigate();

    const ButtonNameArray = [
        { buttonName: "Welcome", buttonNavigate: "/" },
        { buttonName: "Lunch Specials", buttonNavigate: "/Lunch" },
        { buttonName: "Dinner Menu", buttonNavigate: "/Dinner" },
        { buttonName: "Contact Us", buttonNavigate: "/Contact" },
        { buttonName: "Gallery", buttonNavigate: "/Gallery" },
    ]

    const [menuElement, setMenuElement] = React.useState(null);
    const open = Boolean(menuElement);

    const handleClick = (event) => {
        setMenuElement(event.currentTarget);
    };
    const handleClose = () => {
        setMenuElement(null);
    };

    return (
        <>
            {/* Full Screen NavBar */}
            {width > 1000 ?
                <>
                    <StyledBox>
                        <BackgroundAppBar position="static">
                            <Toolbar />
                        </BackgroundAppBar>
                    </StyledBox>
                    <Fade in={true} timeout={500}>
                        <StyledBox>
                            <InnerAppBar position="static" color="transparent">
                                <Toolbar>
                                    <Button onClick={() => {navigate("/");}} sx={{marginLeft: "6%", padding: "0", "&:hover": {color: "transparent", backgroundColor: "transparent"}}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={Logo} alt="LotusLogo" style={{ maxWidth: "90px", maxHeight: "45px"}} />
                                            &nbsp;&nbsp;&nbsp;
                                            <p style={{fontSize: "35px", fontFamily: "Source Sans Pro", fontWeight: "bold", color: "white", margin: "0", marginTop: "1%"}}>LOTUS</p>
                                        </div>
                                    </Button>

                                    <OuterButtonDiv>
                                        {ButtonNameArray.map((currentButton, ID) => {
                                            let active = false;
                                            if (currentButton.buttonNavigate === "/") {
                                                if (window.location.pathname === currentButton.buttonNavigate) {
                                                    active = true;
                                                }
                                            } else {
                                                if (window.location.href.toLowerCase().includes(currentButton.buttonNavigate.toLowerCase())) {
                                                    active = true;
                                                }
                                            }

                                            return (
                                                <StyledButton component={Link} to={`${currentButton.buttonNavigate}`} key={ID} active={active ? "true" : "false"} width={width} buttonarraylength={ButtonNameArray.length} id={ID}>
                                                    {currentButton.buttonName}
                                                </StyledButton>
                                            );
                                        })}
                                    </OuterButtonDiv>
                                </Toolbar>
                            </InnerAppBar>
                        </StyledBox>
                    </Fade>
                </>
            :
            // Mobile NavBar
                <>
                    <StyledBox>
                        <BackgroundAppBar>
                            <Toolbar />
                        </BackgroundAppBar>
                    </StyledBox>
                    <Fade in={true} timeout={500}>
                        <StyledBox>
                            <InnerAppBar position="static" color="transparent">
                                <Toolbar>
                                    <Button onClick={() => {navigate("/");}} sx={{padding: "0", "&:hover": {color: "transparent", backgroundColor: "transparent"}}}>
                                        <StyledTypography
                                        >
                                            <img src={Logo} alt="LotusLogo" style={{ maxWidth: "90px", maxHeight: "40px", marginTop: "15%" }} />
                                        </StyledTypography>
                                    </Button>

                                    <StyledIconButton  onClick={handleClick} >
                                        <StyledMenuIcon />
                                    </StyledIconButton>
                                    <Menu anchorEl={menuElement} open={open} onClose={handleClose}>
                                        {ButtonNameArray.map((currentButton, ID) => {
                                            let active = false;
                                            if (currentButton.buttonNavigate === "/") {
                                                if (window.location.pathname === currentButton.buttonNavigate) {
                                                    active = true;
                                                }
                                            } else {
                                                if (window.location.href.toLowerCase().includes(currentButton.buttonNavigate.toLowerCase())) {
                                                    active = true;
                                                }
                                            }
                                        
                                            return (
                                                <StyledMenuItem component={Link} to={`${currentButton.buttonNavigate}`} active={active ? "true" : "false"} key={ID}>
                                                    {currentButton.buttonName}
                                                </StyledMenuItem>
                                            );
                                        })}
                                    </Menu>
                                </Toolbar>
                            </InnerAppBar>
                        </StyledBox>
                    </Fade>
                </>
        }
        </>
    )

};

export default NavBar;
