export const Appetizers = [
    { number: "101", name: "Spring Rolls (2 pcs)", extraInfo: "", extraInfo2: "", desc: "Deep fried pastry filled with ground chicken, shrimp, glass noodles, taro & veggies; served with Vietnamese fish sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$5.75" },
    { number: "102", name: "Vegetarian Spring Rolls (4pcs)", extraInfo: "", extraInfo2: "", desc: "Deep fried pastry filled with glass noodles & veggies; served with Thai sweet chilli sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, price: "$5.55" },
    { number: "103", name: "Fresh Shrimp Rolls (2pcs)", extraInfo: "BBQ Beef or BBQ Chicken or BBQ Pork + $1", extraInfo2: "", desc: "Delicate rice paper filled with shrimps, vermicelli & lettuce; served with peanut sauce", spice: false, veg: false, chefSpecial: false, peanuts: true, gluten: true, price: "$6.55" },
    { number: "104", name: "Fresh Vegetarian Rolls (2pcs)", extraInfo: "", extraInfo2: "", desc: "Delicate rice paper filled with vermicelli, tofu, & veggies; served with peanut sauce", spice: false, veg: true, chefSpecial: false, peanuts: true, gluten: true, price: "$6.55" },
    { number: "106", name: "Crab Rangoon (6pcs)", extraInfo: "", extraInfo2: "", desc: "Deep fried pastry filled with imitation crab meat, green onions & cream cheese; served with Thai sweet chili sauce", spice: false, veg: false, chefSpecial: true, peanuts: false, gluten: false, price: "$7.99" },
    { number: "107", name: "Chicken Satays (4pcs)", extraInfo: "", extraInfo2: "", desc: "Grilled chicken skewers, marinated in curry sauce; served with Thai peanut sauce", spice: false, veg: false, chefSpecial: true, peanuts: true, gluten: false, price: "$8.99" },
    { number: "108", name: "Calamari", extraInfo: "", extraInfo2: "", desc: "Lightly battered Calamari served with Thai sweet chili sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$8.49" },
    { number: "109", name: "Coconut Butterfly Shrimp (5pcs)", extraInfo: "", extraInfo2: "", desc: "Deep fried marinated shrimp covered with coconut flakes & Thai spices; served with Thai sweet chili sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$8.99" },
    { number: "114", name: "Mango Salad", extraInfo: "", extraInfo2: "", desc: "Shredded mangos tossed in sweet lemon dressing with bell peppers, red onion, herbs & ground peanut & cashew", spice: false, veg: true, chefSpecial: false, peanuts: true, gluten: false, price: "$9.50" },
];

export const SoupAppetizer = [
    { number: "111", name: "Wonton Soup", extraInfo: "", extraInfo2: "", desc: "Ground chicken & shrimp filled wontons served with nappa in chicken broth", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$7.25" },
    { number: "112", name: "Tom Yum", extraInfo: "Please choose from: 112A. Chicken, 112B. Shrimp, 112C. Veggie", extraInfo2: "", desc: "Rich spicy and sour Thai soup with tomatoes, nappa, celery & herbs.", spice: true, veg: false, chefSpecial: true, peanuts: false, gluten: true, price: "$7.50 | $7.50 | $8.50" },
];

export const Pho = [
    { number: "201a", name: "Delux Beef Noodle Soup", extraInfo: "", extraInfo2: "", desc: "Rare, well-done beef, chicken & beef balls in beef noodle soup", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true },
    { number: "202", name: "Rare Lean Beef Noodle Soup", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: true, peanuts: false, gluten: true },
    { number: "202a", name: "Well-Done Flank Beef Noodle Soup", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true },
    { number: "203", name: "Rare Lean, Well-Done Flank Beef Noodle Soup", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true },
    { number: "204", name: "Rare Lean, Well-Done Flank & Beef Ball Noodle Soup", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true },
    { number: "208", name: "Chicken Noodle Soup", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: true, peanuts: false, gluten: true },
    { number: "209", name: "Beef Ball Noodle Soup", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true },
    { number: "210", name: "Rice Noodle Soup", extraInfo: "", extraInfo2: "", desc: "Choice of Extra Noodles, Mix Vegetables or Tofu", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true },
    { number: "211", name: "Lotus Special Pho Sate", extraInfo: "", extraInfo2: "", desc: "Mekong style pho in creamy coconut & peanut sauce", spice: true, veg: false, chefSpecial: true, peanuts: true, gluten: true, price: "$16.49" },
    { number: "212", name: "Shrimp Noodle Soup", extraInfo: "", extraInfo2: "", desc: "Jumbo shrimp in beef noodle soup", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "$18.49" },
    { number: "213", name: "Seafood Noodle Soup", extraInfo: "", extraInfo2: "", desc: "Jumbo shrimp, mussels, calamari, imitation crab, fish balls in beef noodle soup", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: true, price: "$19" },
];

export const OtherSoups = [
    { number: "250", name: "Wonton Noodle Soup", extraInfo: "", extraInfo2: "", desc: "Egg noodle in chicken broth with chicken & shrimp wontons", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$14.99" },
    { number: "251", name: "Hue Style Beef Noodle Soup", extraInfo: "", extraInfo2: "", desc: "Rare, Well-Done flank, beef ball & pork slices in spicy beef broth", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$16.99" },
    { number: "254", name: "Mixed Veggie Noodle Soup", extraInfo: "", extraInfo2: "", desc: "Mixed veggies in chicken broth with rice noodle", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$14.49" },
    { number: "256", name: "Tom Yum Vermicelli Soup", extraInfo: "Please choose from: Chicken, Mixed Veggies, Shrimp, Seafood", extraInfo2: "", desc: "Spicy & sour Thai soup with tomatoes, nappa, celery, pineapple, & herbs", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$16 | $16 | $18.49 | $19.49" },
];

export const ThaiStirFry = [
    { number: "301", name: "Traditional Authentic Pad Thai", extraInfo: "", extraInfo2: "", desc: "Rice noodle with shrimp, chicken, eggs, bean sprouts & tofu", spice: false, veg: true, chefSpecial: true, peanuts: true, gluten: true, price: "$15.49" },
    { number: "302", name: "Curry Pad Thai", extraInfo: "", extraInfo2: "", desc: "Tradiational pad Thai in curry sauce", spice: true, veg: true, chefSpecial: true, peanuts: true, gluten: true, price: "$15.99" },
    { number: "303", name: "Nasi Goreng", extraInfo: "", extraInfo2: "", desc: "Fried rice with tiger shrimp, chicken & eggs in curry sauce; topped with two chicken satay & Thai peanut sauce", spice: false, veg: false, chefSpecial: true, peanuts: true, gluten: false, price: "$17.49" },
    { number: "304", name: "Bami Goreng", extraInfo: "", extraInfo2: "", desc: "Stir fried egg noodle with tiger shrimp, chicken, eggs & mixed veggies in curry sauce; topped with two chicken satay & Thai peanut sauce", spice: true, veg: false, chefSpecial: true, peanuts: true, gluten: false, price: "$17.49" },
    { number: "305", name: "Crispy Tamarind Fish", extraInfo: "", extraInfo2: "", desc: "Fish fillet in taramind sauce with onions, bell peppers, ginger & fried shallots", spice: false, veg: false, chefSpecial: true, peanuts: false, gluten: false, price: "$17.49" },
    { number: "306", name: "Crispy Mango Fish", extraInfo: "", extraInfo2: "", desc: "Crispy fish fillet topped with fresh Thai style mango salad", spice: false, veg: false, chefSpecial: true, peanuts: true, gluten: false, price: "$17.99" },
    { number: "307", name: "Thai Spicy Eggplant Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Egg plants, bell peppers, onions & basil leaves in spicy Thai sauce", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "310", name: "Lotus Special Pad Thai", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Rice noodle with eggs, bean sprouts, bell peppers, carrots & onions", spice: true, veg: false, chefSpecial: true, peanuts: true, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "311", name: "Singapore Noodle", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Singapore style vermicelli with eggs, carrots & bell peppers", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "312", name: "Drunken Noodle Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Rice noodle in spicy sauce with eggs, bok choy, carrots, bell peppers & basil", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "313", name: "Thai Tamarind Sauce Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Crispy meat in Thai tamarind sauce with onions, ginger & peppers", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "314", name: "Thai Sweet and Sour Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Crispy meat in Thai sweet & sour sauce with pineapples, cucumbers, tomatoes, bell peppers & onions", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "315", name: "Thai Spicy Coconut Curry Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fried with basil leaves, green beans, bamboo shoots, carrots, bell peppers & chillies", spice: true, veg: true, chefSpecial: true, peanuts: true, gluten: false, price: "$15.99 | $17.49 | $17.99 | $19.49" },
    { number: "316", name: "Thai Spicy Basil Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fried with bell peppers, carrots, onions & fresh basil", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "317", name: "Thai Cashew Nut Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fried cashew nuts with onions, bell peppers, oranges & carrots", spice: false, veg: false, chefSpecial: false, peanuts: true, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "318", name: "Mixed Vegetables Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fried mixed vegetables in Thai sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "319", name: "Pad See Ew", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fried thick rice noodle with mix vegetables in sweet soy sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "319A", name: "Spicy Pad See Ew", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fried thick rice noodle with beansprout & onions in spicy sauce", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "320", name: "Black Pepper Beef Stir Fry", extraInfo: "", extraInfo2: "", desc: "Tender sliced beef stir fried with onions & bell peppers; served with steam rice", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$16.49" },
];

export const ThaiCurry = [
    { number: "330", name: "Green Curry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp", extraInfo2: "", desc: "Green curry with bamboo shoots, bell peppers, onions & basil leaves", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: true },
    { number: "331", name: "Golden Curry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp", extraInfo2: "", desc: "Yellow curry with tomatoes, potatoes, onions & lime leaves", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: true },
    { number: "332", name: "Red Curry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp", extraInfo2: "", desc: "Red curry with bamboo shoots, bell peppers, onions & basil leaves", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: true },
    { number: "333", name: "Penang Curry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp", extraInfo2: "", desc: "Red curry with peanut sauce, green beans, zucchini & lime leaves", spice: true, veg: true, chefSpecial: true, peanuts: false, gluten: true },
    { number: "334", name: "Pineapple Curry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp", extraInfo2: "", desc: "Red curry with pineapples, tomatoes, onions & bell peppers", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: true },
];

export const FromTheWok = [
    { number: "400", name: "Egg Noodle Stir Fry", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fried egg noodles with mixed veggies in light soy sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "401", name: "Udon Noodle in Black Bean Sauce", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", desc: "Stir fried udon noodle with mixed veggies in black bean sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "402", name: "Black Bean Sauce Stir Fry", extraInfo: "Can be served on a bed of warm vermicelli + $2", extraInfo2: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", desc: "Stir fried mixed veggies in black bean sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "403", name: "Fresh Lemongrass Stir Fry", extraInfo: "Can be served on a bed of warm vermicelli + $2", extraInfo2: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", desc: "Stir fried mixed veggies in fresh lemongrass sauce", spice: true, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "404", name: "Stir Fry Yellow Curry", extraInfo: "Can be served on a bed of warm vermicelli + $2", extraInfo2: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", desc: "Stir fried mixed veggies in coconut milk curry sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "456", name: "Stir Fry Broccoli", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Stir fry broccoli in light soy sauce & garlic", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
];

export const FriedRice = [
    { number: "450", name: "Fried Rice", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Jasmine rice with eggs, carrots, peas & onions", spice: false, veg: true, chefSpecial: true, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "451", name: "Pineapple Fried Rice", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Jasmine rice with pineapples, eggs, carrots, peas & onions", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "452", name: "Thai Spicy Fried Rice", extraInfo: "Please choose from: Chicken or Mixed Veggies or Tofu, Beef, Shrimp, Seafood", extraInfo2: "", desc: "Jasmine rice in Thai sauce with basil, mixed veggies & a sunny side up egg", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49 | $16.99 | $17.49 | $18.99" },
    { number: "454", name: "Lotus Special Fried Rice", extraInfo: "", extraInfo2: "", desc: "Fried rice with shrimp, chicken, eggs, tofu, onions & bean sprouts", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: true, price: "$15.79" },
    { number: "455", name: "Pad Kra Pao", extraInfo: "Please choose from: Chicken, Beef", extraInfo2: "", desc: "Stir fried Thai spicy sauce with basil, green beans, bell pepper & onion over jasmine rice; topped with sunnyside up egg", spice: true, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15.99 | $17.49" },
];

export const VermicelliBowl = [
    { number: "501", name: "BBQ Pork or BBQ Chicken & Spring Roll", extraInfo: "Meat Lovers + $4.50", extraInfo2: "", desc: "Served with shredded lettuce, bean sprouts, cucumbers & pickled carrots. Served with a side of fish sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15" },
    { number: "502", name: "BBQ Beef & Spring Rolls", extraInfo: "", extraInfo2: "", desc: "Served with shredded lettuce, bean sprouts, cucumbers & pickled carrots. Served with a side of fish sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$16.99" },
    { number: "505", name: "BBQ Lemongrass Chicken Breast & Spring Roll", extraInfo: "", extraInfo2: "", desc: "Served with shredded lettuce, bean sprouts, cucumbers & pickled carrots. Served with a side of fish sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15.99" },
    { number: "506", name: "BBQ Shrimp & Spring Roll", extraInfo: "", extraInfo2: "", desc: "Served with shredded lettuce, bean sprouts, cucumbers & pickled carrots. Served with a side of fish sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$17.99" },
    { number: "507", name: "BBQ Shrimp, Lemongrass Chicken & Spring Roll", extraInfo: "", extraInfo2: "", desc: "Served with shredded lettuce, bean sprouts, cucumbers & pickled carrots. Served with a side of fish sauce", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$17.49" },
    { number: "508", name: "Vegan Vermicelli Bowl", extraInfo: "", extraInfo2: "", desc: "Vegetarian spring rolls, fried tofu, bell peppers & mangos; served with Thai sweet chilli sauce & soy sauce", spice: false, veg: true, chefSpecial: false, peanuts: false, gluten: true, price: "16.49" },
];

export const RicePlate = [
    { number: "601", name: "BBQ Pork Chop or BBQ Chicken", extraInfo: "Meat lovers + $4.50", extraInfo2: "", desc: "Traditional Vietnamese style rice plate served with jasmine rice, broccoli, pickled carrots, sliced tomatoes & cucumbers", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15" },
    { number: "602", name: "Sunnyside Up Egg, BBQ Pork or BBQ Chicken", extraInfo: "Meat lovers + $4.50", extraInfo2: "", desc: "Traditional Vietnamese style rice plate served with jasmine rice, broccoli, pickled carrots, sliced tomatoes & cucumbers", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15.49" },
    { number: "603", name: "BBQ Beef & Sunnyside Up Egg", extraInfo: "Meat lovers + $4.50", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$16.99" },
    { number: "604", name: "Slice Lemongrass Chicken Breast & Sunnyside Up Egg", extraInfo: "Meat lovers + $4.50", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$15.99" },
];

export const SideOrders = [
    { number: "350", name: "Steam Rice", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$2" },
    { number: "351", name: "Coconut Rice", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$3.25" },
    { number: "352", name: "Sticky Rice", extraInfo: "", extraInfo2: "", desc: "", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$4.25" },
    { number: "655", name: "Steam Vegetables", extraInfo: "", extraInfo2: "", desc: "Broccoli or Bok Choy or Mix Veggies", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$4.99" },
];

export const Dessert = [
    { number: "", name: "Mango Sticky Rice", extraInfo: "", extraInfo2: "", desc: "Sweet steamed sticky rice with fresh sliced yellow mangos; drizzled with thick coconut milk, dried coconut flakes and sesame seeds", spice: false, veg: false, chefSpecial: false, peanuts: false, gluten: false, price: "$10.99" },
];