import React from "react";
import { Grid, Typography, IconButton, Tooltip, Button, Menu, MenuItem, Fade } from "@mui/material";
import { GiChiliPepper, GiPeanut } from "react-icons/gi";
import { FaLeaf } from "react-icons/fa";
import { IoRestaurantSharp } from "react-icons/io5";
import GlutenFree from "../images/glutenFreeImage.png";
import NavBar from "../Components/NavBar";
import FirstImageJPG from "../images/LunchPageImages/FirstImage.jpg";
import FirstImageWEBP from "../images/LunchPageImages/FirstImage.webp";
import FirstImageJPGMobi from "../images/LunchPageImages/FirstImageMobi.jpg";
import FirstImageWEBPMobi from "../images/LunchPageImages/FirstImageMobi.webp";
import { useWidth } from "../WidthContext";
import styled from "styled-components";
import Footer from "../Components/Footer";
import { CgMenuGridO } from "react-icons/cg";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Scroll } from "react-fns";
import LunchImage1PNG from "../images/LunchPageImages/LunchImage1.png";
import LunchImage2PNG from "../images/LunchPageImages/LunchImage2.png";
import LunchImage3PNG from "../images/LunchPageImages/LunchImage3.png";
import LunchImage4PNG from "../images/LunchPageImages/LunchImage4.png";
import LunchImage1WEBP from "../images/LunchPageImages/LunchImage1.webp";
import LunchImage2WEBP from "../images/LunchPageImages/LunchImage2.webp";
import LunchImage3WEBP from "../images/LunchPageImages/LunchImage3.webp";
import LunchImage4WEBP from "../images/LunchPageImages/LunchImage4.webp";

import { JasmineStirFry, ThaiCoconutcurry, NoodleStirFry, NoodleSoup } from "./assets";

const LunchPage = () => {
    const { width } = useWidth();
    const [menuElement, setMenuElement] = React.useState(null);
    const open = Boolean(menuElement);

    const handleClick = (event) => {
        setMenuElement(event.currentTarget);
    };
    const handleClose = () => {
        setMenuElement(null);
    };

    // Forces the page to start at the top if the page is loaded
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{position: "absolute", top: "0", left: "0", width: "100%", overflowX: "hidden"}}>
            {/* Lunch Special Header Section */}
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "50vh"}}>
                <picture style={{height: "50vh"}}>
                    <source srcSet={width > 1300 ? FirstImageWEBP : FirstImageWEBPMobi} type="image/webp" />
                    <source srcSet={width > 1300 ? FirstImageJPG : FirstImageJPGMobi} type="image/jpg" />
                    <img src={width > 1300 ? FirstImageJPG : FirstImageJPGMobi} style={{width: "100vw", height: "50vh", objectFit: "cover"}} alt="LunchHeaderImage" />
                </picture>
                <p style={{position: "absolute", color: "white", fontFamily: "Montserrat", fontSize: "40px", fontWeight: "bold"}}>LUNCH SPECIALS</p>
            </div>



            {/* Food Category Bar Section */}
            {width > 1300 &&
                <div style={{backgroundColor: "rgba(226, 163, 0, 0.7)", width: "100vw", height: "7vh", display: "flex", alignItems: "center", paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%"}}> 
                    <Button href="#JasmineRiceStirFry" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1500 ? "16px" : "14px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Jasmine Rice Stir Fry</Button>
                    <Button href="#ThaiCoconutCurry" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1500 ? "16px" : "14px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Thai Coconut Curry</Button>
                    <Button href="#NoodleStirFry" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1500 ? "16px" : "14px", "&:hover": {color: "white", backgroundColor: "transparent"}}}>Noodle Stir Fry</Button>
                    <Button href="#NoodleSoup" variant="standard" sx={{fontFamily: "Source Sans Pro", color: "black", fontWeight: 800, fontSize: width > 1500 ? "16px" : "14px","&:hover": {color: "white", backgroundColor: "transparent"}}}>Noodle Soup</Button>
                </div>
            }

 

            {/* Lunch Special Information Section */}
            <Grid container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "3%" : width > 900 ? "5%" : width > 600 ? "6%" : "10%"}}>
                <Grid item>
                    <Typography variant={width > 1300 ? "h5": "h6"} sx={{fontFamily: "Montserrat", fontWeight: 800}}>Lunch Hours</Typography>
                    <Typography variant={width > 1300 ? "h6": "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: 400}}>Tue - Fri&nbsp;&nbsp;&nbsp;11AM - 3PM</Typography>
                    <br />
                    {width > 1300 && <br />}
                    <Typography variant={width > 1300 ? "h5": "h6"} sx={{fontFamily: "Montserrat", fontWeight: 800}}>Extra Information</Typography>
                    {width > 1300 ?
                        <>
                            <Typography variant={width > 1300 ? "h6": "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: 400}}>All lunch specials include a vegetarian spring roll</Typography>
                            <Typography variant={width > 1300 ? "h6": "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: 400}}>with a side of salad with housemade dressing</Typography>
                        </>
                    :
                        <Typography variant={width > 1300 ? "h6": "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: 400}}>All lunch specials include a vegetarian spring roll with a side of salad with housemade dressing</Typography>
                    }
                    <br />
                    {width > 1300 ?
                        <>
                            <Typography variant={width > 1300 ? "h6": "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: 800}}>All dinner items are available during this time with</Typography>
                            <Typography variant={width > 1300 ? "h6": "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: 800}}>special deals on specific items listed below</Typography>
                        </>
                    :
                        <Typography variant={width > 1300 ? "h6": "subtitle1"} sx={{fontFamily: "Montserrat", fontWeight: 800}}>All dinner items are available during this time with special deals on specific items listed below</Typography>
                    }
                </Grid>
            </Grid>



            {/* Legend Section */}
            {width > 1300 ? 
                <Grid container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "3%" : width > 900 ? "4%" : width > 600 ? "6%" : "8%"}}>
                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Spice&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiChiliPepper style={{color: "#E91919"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Spicy</Typography>
                    </Grid>

                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Peanuts&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiPeanut style={{color: "#C97E27"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Peanuts</Typography>
                    </Grid>

                    <Grid item sx={{display: "flex", flexWrap: "row", alignItems: "center", paddingLeft: "2%", paddingRight: "2%"}}>
                        <Tooltip title="&nbsp;Gluten Free Option&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <img src={GlutenFree} alt="GlutenFreeImage" style={{height: "1em", width: "1em"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Gluten Free Option</Typography>
                    </Grid>
                </Grid>
            :
                <Grid container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "2%" : width > 900 ? "3%" : width > 600 ? "4%" : "6%"}}>
                    <Grid item xs={width > 1300 ? 12 : 6} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Spice&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiChiliPepper style={{color: "#E91919"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Spicy</Typography>
                        <br />
                        <br />
                        <br />
                    </Grid>
                    {width <= 1300 &&
                        <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
                            <IconButton onClick={handleClick}>
                                <Typography sx={{fontFamily: "Montserrat", color: "black"}}>Filters&nbsp;&nbsp;</Typography>
                                <CgMenuGridO style={{color: "black"}} />
                            </IconButton>
                            <Menu anchorEl={menuElement} open={open} onClose={handleClose}>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#JasmineRiceStirFry" style={{textDecoration: "none", color: "black"}}>Jasmine Rice Stir Fry</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#ThaiCoconutCurry" style={{textDecoration: "none", color: "black"}}>Thai Coconut Curry</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#NoodleStirFry" style={{textDecoration: "none", color: "black"}}>Noodle Stir Fry</a></MenuItem>
                                <MenuItem onClick={handleClose} sx={{fontFamily: "Source Sans Pro"}}><a href="#NoodleSoup" style={{textDecoration: "none", color: "black"}}>Noodle Soup</a></MenuItem>
                            </Menu>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Peanuts&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <GiPeanut style={{color: "#C97E27"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Peanuts</Typography>
                        <br />
                        <br />
                        <br />

                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", flexWrap: "row", alignItems: "center"}}>
                        <Tooltip title="&nbsp;Gluten Free Option&nbsp;" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px"}}}}>
                            <IconButton sx={{padding: "0"}}>
                                <img src={GlutenFree} alt="GlutenFreeImage" style={{height: "1em", width: "1em"}} />
                            </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Gluten Free Option</Typography>
                        <br />
                        <br />
                        <br />

                    </Grid>
                </Grid>
            }



            {/* Jasmine Rice Stir Fry Section */}
            <Grid id="JasmineRiceStirFry" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "3%" : width > 900 ? "5%" : width > 600 ? "5%" : "8%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>JASMINE RICE STIR FRY</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: width > 1300 ? "1%" : width > 900 ? "2%" : width > 600 ? "3%" : "4%", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Chicken or Tofu or Mix Veggies $14.49 | Beef $15.49 | Shrimp $16.49</Typography>
                </Grid>

                <ItemCard itemArray={JasmineStirFry} width={width} />
            </Grid>



            {/* Thai Coconut Curry Section */}
            <Grid id="ThaiCoconutCurry" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>THAI COCONUT CURRY</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: width > 1300 ? "1%" : width > 900 ? "2%" : width > 600 ? "3%" : "4%", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Chicken or Tofu or Mix Veggies $15 | Beef $16 | Shrimp $17</Typography>
                </Grid>

                <ItemCard itemArray={ThaiCoconutcurry} width={width} />
            </Grid>



            {/* Noodle Stir Fry Section */}
            <Grid id="NoodleStirFry" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>NOODLE STIR FRY</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: width > 1300 ? "1%" : width > 900 ? "2%" : width > 600 ? "3%" : "4%", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>Chicken or Tofu or Mix Veggies $14.49 | Beef $15.49 | Shrimp $16.49</Typography>
                </Grid>

                <ItemCard itemArray={NoodleStirFry} width={width} />
            </Grid>



            {/* Noodle Soup Section */}
            <Grid id="NoodleSoup" container sx={{paddingLeft: width > 900 ? "10%" : "6%", paddingRight: width > 900 ? "10%" : "6%", marginTop: width > 1300 ? "5%" : width > 900 ? "7%" : width > 600 ? "9%" : "15%"}}>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Typography align="center" variant="h4" sx={{fontFamily: "Montserrat", fontWeight: 800, borderBottom: "2px solid #525252"}}>NOODLE SOUPS</Typography>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: width > 1300 ? "1%" : width > 900 ? "2%" : width > 600 ? "3%" : "4%", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : width > 600 ? "5%" : "8%"}}>
                    <Typography align="center" variant="subtitle1" sx={{fontFamily: "Montserrat"}}>| $14.49 |</Typography>
                </Grid>

                <ItemCard itemArray={NoodleSoup} width={width} />
            </Grid>



            {/* Image top line section */}
            <Grid container sx={{marginTop: width > 1300 ? "6%" : width > 900 ? "8%" : width > 600 ? "10%" : "16%", marginBottom: "10px"}}>
                {width > 700 &&
                    <>
                        <Grid item xs={3}>
                            <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                        </Grid>
                        <Grid item xs={3}>
                            <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                        </Grid>
                    </>
                }
                <Grid item xs={width > 700 ? 3 : 6}>
                    <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                </Grid>
                <Grid item xs={width > 700 ? 3 : 6}>
                    <hr style={{width: "85%", border: "1px solid #D7DEE6"}} />
                </Grid>
            </Grid>



            {/* Image Section */}
            <Grid container>
                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingRight: width > 700 ? "" : "2px", paddingBottom: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 21</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>Rare Beef Noodle Soup</Typography>
                    </div>
                    <picture>
                        <source srcSet={LunchImage1WEBP} type="image/webp" />
                        <source srcSet={LunchImage1PNG} type="image/png" />
                        <img src={LunchImage1PNG} alt="LunchImage1" height="100%" width="100%" />
                    </picture>
                </Grid>

                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingLeft: width > 700 ? "" : "2px", paddingBottom: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 19</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>Egg Noodle Stir Fry</Typography>
                    </div>
                    <picture>
                        <source srcSet={LunchImage2WEBP} type="image/webp" />
                        <source srcSet={LunchImage2PNG} type="image/png" />
                        <img src={LunchImage2PNG} alt="LunchImage2" height="100%" width="100%" />
                    </picture>
                </Grid>

                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingRight: width > 700 ? "" : "2px", paddingTop: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 18</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>Drunken Noodle Stir Fry</Typography>
                    </div>
                    <picture>
                        <source srcSet={LunchImage3WEBP} type="image/webp" />
                        <source srcSet={LunchImage3PNG} type="image/png" />
                        <img src={LunchImage3PNG} alt="LunchImage3" height="100%" width="100%" />
                    </picture>
                </Grid>

                <Grid item xs={width > 700 ? 3 : 6} sx={{paddingLeft: width > 700 ? "" : "2px", paddingTop: width > 700 ? "" : "2px", position: "relative", transition: "0.2s", "&:hover": {opacity: "90%"}}}>
                    <div style={{position: "absolute", width: "92%", padding: "4%"}}>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>No. 5</Typography>
                        <Typography variant={width > 700 ? "subtitle1" : "subtitle2"} sx={{fontFamily: "Montserrat", color: "white"}}>Thai Spicy Coconut Curry Stir Fry</Typography>
                    </div>
                    <picture>
                        <source srcSet={LunchImage4WEBP} type="image/webp" />
                        <source srcSet={LunchImage4PNG} type="image/png" />
                        <img src={LunchImage4PNG} alt="LunchImage4" height="100%" width="100%" />
                    </picture>
                </Grid>
            </Grid>



            {/* Arrow to scroll up */}
            <Scroll 
                render={({y}) => (
                    <Fade in={y > 800} timeout={1000}>
                        <div style={{position: "fixed", bottom: "5%", left: "50%", transform: "translate(-50%, 0)"}}>
                            <IconButton onClick={() => {window.scrollTo(0, 0);}} sx={{boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.5)", transition: "0.2s", backgroundColor: "#303030", "&:hover": {backgroundColor: "black", transform: "translateY(-4px)"}}}>
                                <KeyboardArrowUpIcon sx={{color: "white"}} />
                            </IconButton>
                        </div>
                    </Fade>
                )}
            />



            {/* Footer and NavBar */}
            <Footer />
            <NavBar />
        </div>
    );
};

export default LunchPage;


const ItemCard = ({ itemArray, width }) => {
    return (
        <>
            {itemArray.map((item, ID) => (
                <React.Fragment key={ID}>
                    {width > 1300 && ID % 3 === 1 && <Grid item xs={1.05} />}
                    <Grid key={ID} item xs={width > 1300 ? 3.3 : width > 900 ? 5.5 : 12} sx={{marginTop: "1%", borderBottom: "1px solid #D7DEE6", marginBottom: width > 1300 ? "2%" : width > 900 ? "4%" : "5%", position: "relative"}}>
                        <Typography variant="h6" sx={{fontFamily: "Montserrat", fontWeight: 800}}>{item.number} - {item.name}</Typography>
                        <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", marginTop: "2%"}}>{item.desc}</Typography>

                        {item.extraInfo !== "" && <Typography variant="subtitle1" sx={{fontFamily: "Montserrat", marginTop: "2%"}}>{item.extraInfo}</Typography>}
                        
                        <p style={{ fontFamily: "Montserrat", position: "absolute", bottom: "5px", right: "5px" }}>{item.price}</p>

                        <br />
                        {item.spice === true && 
                            <>
                                <Tooltip title="Spice" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <GiChiliPepper style={{color: "#E91919"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.veg === true && 
                            <>
                                <Tooltip title="Vegetarian" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <FaLeaf style={{color: "#2B8E03"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.chefSpecial === true && 
                            <>
                                <Tooltip title="Chef's Special" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <IoRestaurantSharp style={{color: "#AAAAAA"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.peanuts === true && 
                            <>
                                <Tooltip title="Peanuts" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <GiPeanut style={{color: "#C97E27"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {item.gluten === true && 
                            <>
                                <Tooltip title="Gluten Free Option" placement="top" componentsProps={{tooltip: {sx: {backgroundColor: "white", color: "black", border: "1px solid #D7D6D6", fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "20px", paddingRight: "16px", paddingLeft: "16px"}}}}>
                                    <IconButton sx={{padding: "0"}}>
                                        <img src={GlutenFree} alt="GlutenFreeImage" style={{height: "1em", width: "1em"}} />
                                    </IconButton>
                                </Tooltip>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>}
                        {(item.spice === true || item.veg === true || item.chefSpecial === true || item.peanuts === true || item.gluten === true) && <><br /><br /></>}
                    </Grid>
                    
                    {width > 1300 && ID % 3 === 1 && <Grid item xs={1.05} />}
                    {width <= 1300 && width > 900 && ID % 2 === 0 && <Grid item xs={1} />}
                </React.Fragment>
            ))}
        </>
    )
}


export const PhoneAnchor = styled.a`
    text-decoration: none;
    color: #4277FF;
    &: hover {
        text-decoration: underline;
    }
`;