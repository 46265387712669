import styled from "styled-components";
import { Button, Grid, IconButton, Table, TableCell, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
// Outer Container that wraps the whole page
export const OuterContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow-x: hidden;
`;



// Location Header styled components
export const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;
`;

export const StyledImage = styled.img`
    width: 100vw;
    height: 50vh;
    object-fit: cover;
`;

export const StyledParagraph = styled.p`
    position: absolute;
    color: white;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
`;



// Full Screen Information Section
export const FullGridContainer = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        margin-top: 5%;
        margin-bottom: 5%;
    }
`;

export const PhoneAnchor = styled.a`
    text-decoration: none;
    color: #4277FF;
    &: hover {
        text-decoration: underline;
    }
`;

export const StyledIconButtonOne = styled(IconButton)`
    && {
        margin-left: ${({width}) => width > 1400 ? "-38%" : "-43%"};
        margin-top: 4%;
        transition: transform 0.2s;
        &:hover {
            transform: translateY(-4px);
        }
        padding: 0;
    }
`;

export const StyledIconButtonSecond = styled(IconButton)`
    && {
        transition: transform 0.2s;
        margin-top: 3.2%;
        &:hover {
            transform: translateY(-4px);
        }
        padding: 0;
    }
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
    && {
        font-size: 29px;
        color: #bc2a8d;
    }
`;

export const StyledFacebookIcon = styled(FacebookIcon)`
    && {
        font-size: 30px;
        color: #4267B2;
        margin-top: 11%;
    }
`;

export const FullStyledTable = styled(Table)`
    && {
        margin-top: 2%;
        margin-left: 4%;
    }
`;

export const FullStyledHeaderTableCell = styled(TableCell)`
    && {
        border-bottom: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 2px;
        padding-bottom: 2px;
    }
`;

export const FullStyledSecondaryTableCell = styled(TableCell)`
    && {
        border-bottom: none;
        padding: 0;
    }
`;

export const FullStyledLocationTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 800;
        font-size: 26px;
    }
`;

export const FullStyledLocationInfoTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-top: 2%;
    }
`;

export const FullDirectionsButton = styled(Button)`
    && {
        margin-top: 6%;
        border-color: #2B8E03;
        color: #2B8E03;
        font-family: Montserrat;
        font-weight: 800;
        &:hover {
            border-color: #2B8E03;
        }
    }
`;

export const FullHoursHeaderTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 800;
        font-size: 26px;
        margin-left: 4%;
    }
`;

export const FullHoursInformationTypography = styled(Typography)`
    && {
        font-family: Montserrat;
    }
`;

export const FullHoursInformationTypographyShifted = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-left: 3.1%;
    }
`;

export const FullExtraInfoHeaderTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 800;
        font-size: 26px;
        margin-left: 4%;
    }
`;

export const FullExtraInfoInformationTypography = styled(Typography)`
    && {
        font-family: Montserrat;
    }
`;

export const FullExtraInfoInformationTypographyShifted = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-left: 2.5%;
    }
`;



// Mobile Information Section
export const MobileContactContainer = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 1000 ? "6%" : width > 600 ? "8%" : "12%"};
        margin-bottom: ${({width}) => width > 1000 ? "6%" : width > 600 ? "8%" : "12%"};
        padding-left: 6%;
        padding-right: 6%;
    }
`;

export const MobileGridItem = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 1000 ? "3%" : width > 600 ? "5%" : "9%"};
    }
`;

export const MobileContactTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 800;
    }
`;

export const MobilePhoneNumberTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 400;
    }
`;

export const MobileIconButton = styled(IconButton)`
    && {
        padding: 0;
        margin-top: ${({width}) => width > 500 ? "1%" : "1.5%"};
    }
`;

export const MobileInstagramIcon = styled(InstagramIcon)`
    && {
        font-size: 24px;
        color: #bc2a8d;
    }
`;

export const MobileFacebookIcon = styled(FacebookIcon)`
    && {
        font-size: 25px;
        color: #4267B2;
    }
`;

export const MobileLocationTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 800;
    }
`;

export const MobileLocationInfoTypograpy = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-top: 1%;
    }
`;

export const MobileDirectionsButton = styled(Button)`
    && {
        font-size: 12px;
        font-weight: 800;
        font-family: Montserrat;
        margin-top: 5%;
        border-color: #2B8E03;
        color: #2B8E03;
        &:hover {
            border-color: #2B8E03;
        }
    }
`;

export const MobileHoursTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 800;
    }
`;

export const MobileStyledTable = styled(Table)`
    && {
        margin-top: 1%;
    }
`;

export const MobileStyledTableCell = styled(TableCell)`
    && {
        border-bottom: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 2px;
        padding-bottom: 2px;
    }
`;

export const MobileInformationTypography = styled(Typography)`
    && {
        font-family: Montserrat;
    }
`;

export const MobileInformationTypographyShifted = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-left: 0.25%;
    }
`;

export const MobileExtraInfoHeaderTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        font-weight: 800;
    }
`;

export const MobileExtraInfoTypography = styled(Typography)`
    && {
        font-family: Montserrat;
    }
`;

export const MobileExtraInfoLunchTypography = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-top: 1%;
    }
`;

export const MobileExtraInfoTypographyShifted = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-left: 0.25%;
    }
`;

export const MobileExtraInfoTypographyDinner = styled(Typography)`
    && {
        font-family: Montserrat;
        margin-top: 4%;
    }
`;