import styled from "styled-components";
import { useWidth } from "../WidthContext";

const GoogleMaps = () => {
    const { width } = useWidth();

    return (
        <GoogleMapDiv className="mapouter" width={width}>
            <div className="gmap_canvas">
                <iframe
                    width="100%"
                    height={width > 1500 ? "380px" : width > 1000 ? "330px" : width > 700 ? "300px" : "250px"}
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=lotus%20mud%20street&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    title="LotusMap"
                />
                <br />
                <style dangerouslySetInnerHTML={{__html: `.mapouter{position:relative;text-align:right;height:${width > 1500 ? "380px" : width > 1000 ? "330px" : width > 700 ? "300px" : "250px"};width:100%;}`}} />
                <style dangerouslySetInnerHTML={{__html: `.gmap_canvas {overflow:hidden;background:none!important;height:${"380px"};width:100%;}`}} />
            </div>
        </GoogleMapDiv>
    )
};

export default GoogleMaps;

export const GoogleMapDiv = styled.div`
    && {
        height: ${({width}) => width > 1500 ? "380px" : width > 1000 ? "330px" : width > 700 ? "300px" : "250px"};
    }
`;
