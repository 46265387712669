import styled from "styled-components";
import { Grid, Typography, IconButton, Button } from "@mui/material";
import { CgFacebook, CgArrowLongDown } from 'react-icons/cg'
import InstagramIcon from "@mui/icons-material/Instagram";
import mediumImage from "../images/WelcomePageImages/medimage.png";
import mobileImage from "../images/WelcomePageImages/mobileimage.png";
import CircleIcon from '@mui/icons-material/Circle';

// Outer Div wraps whole page
export const OuterDiv = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
`;



// Landing Page Styled Components
export const LandingPageDiv = styled.div`
    background-color: rgba(200, 167, 120, 0.2);
    min-height: 100vh;
`;

// Large Screen Device
export const FullSocialsGrid = styled(Grid)`
    && {
        display: flex;
        align-items: end;
    }
`;

export const FullStyledIconButtons = styled(IconButton)`
    && {
        transition: transform 0.2s;
        &:hover {
            transform: translateY(${({animationdirection}) => animationdirection === "up" ? "-5px" : "5px"});
        }
    }
`;

export const FullStyledFacebookIcon = styled(CgFacebook)`
    font-size: ${({width}) => width > 1300 ? "22px" : width > 1100 ? "20px" : "18px"};
    color: #5C5C5C;
`;

export const FullStyledInstagramIcon = styled(InstagramIcon)`
    && {
        font-size: ${({width}) => width > 1300 ? "24px" : width > 1100 ? "22px" : "20px"};
        color: #5C5C5C;
    }
`;

export const FullStyledDownArrowIcon = styled(CgArrowLongDown)`
    font-size: ${({width}) => width > 1300 ? "22px" : width > 1100 ? "20px" : "18px"};
    color: #5C5C5C;
`;

export const FullTextGrid = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }
`;

export const FullTextContainer = styled(Grid)`
    && {
        margin-left: -10%;
    }
`;

export const FullLotusLogoGrid = styled(Grid)`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const FullWelcomeTypography = styled(Typography)`
    && {
        font-family: Ubuntu, sans-serif;
        font-weight: bold;
    }
`;

export const FullAuthenticTextGrid = styled(Grid)`
    && {
        margin-top: 1%;
    }
`;

export const FullAuthenticTextTypography = styled(Typography)`
    && {
        font-family: Mukta, sans-serif;
        font-weight: 300;
    }
`;

export const FullMenuButtonGrid = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        margin-top: ${({width}) => 1600 ? "1%" : width > 1300 ? "0%" : width > 1100 ? "-2%" : "-4%"};
    }
`;

export const FullLunchButton = styled(Button)`
    && {
        font-family: Source Sans Pro;
        font-weight: bold;
        font-size: ${({width}) => width > 1600 ? "16px" : width > 1300 ? "13px" : width > 1100 ? "10px" : "9px"};
        color: #2B8E03;
        border-color: #2B8E03;
        &:hover {
            border-color: #2B8E03;
        }
        border-radius: 10px;
    }
`;

export const FullDinnerButton = styled(Button)`
    && {
        font-family: Source Sans Pro;
        font-weight: bold;
        font-size: ${({width}) => width > 1600 ? "16px" : width > 1300 ? "13px" : width > 1100 ? "10px" : "9px"};
        color: white;
        background-color: #2B8E03;
        border-radius: 10px;
        &:hover {
            background-color: #298105;
        }
    }
`;

export const FullLotusFoodImageGrid = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

// Medium screen Device
export const MedGridContainer = styled(Grid)`
    && {
        background-image: url(${mediumImage});
        height: 100vh;
        background-size: cover;
        background-position: center;
    }
`;

export const MedGridItem = styled(Grid)`
    && {
        margin-top: 45vh;
        padding-left: 4%;
        padding-right: 4%;
    }
`;

export const MedLotusTypography = styled(Typography)`
    && {
        color: white;
        font-family: Patrick Hand;
        font-weight: bold;
    }
`;

export const MedAuthenticTypography = styled(Typography)`
    && {
        margin-top: 15px;
        color: white;
        font-family: Montserrat;
        font-weight: 600;
    }
`;

export const MedButtonGrid = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
    }
`;

export const MedLunchDinnerButtons = styled(Button)`
    && {
        text-align: center;
        border-color: transparent;
        border-radius: 5px;
        font-family: Source Sans Pro;
        font-size: 16px;
        font-weight: bold;
        padding: 2%;
        color: white;
        &:hover {
            transform: perspective(700px) translateZ(25px);
            border-color: white;
        }
    }
`;

export const MedSocialIconGrids = styled(Grid)`
    && {
        position: absolute;
        top: ${({top}) => top};
        left: 5vw;
    }
`;

export const MedStyledIconButton = styled(IconButton)`
    && {
        transition: transform 0.2s;
        &:hover {
            transform: translateY(${({animationdirection}) => animationdirection === "up" ? "-5px" : "5px"});
        }
    }
`;

export const MedStyledFacebookIcon = styled(CgFacebook)`
    font-size: 22px;
    color: white;
`;

export const MedStyledInstagramIcon = styled(InstagramIcon)`
    && {
        font-size: 24px;
        color: white;
    }
`;

export const MedStyledDownArrowIcon = styled(CgArrowLongDown)`
    font-size: 24px;
    color: white;
`;

// Mobile Device
export const MobileGridContainer = styled(Grid)`
    && {
        background-image: url(${mobileImage});
        height: 100vh;
        background-size: cover;
        background-position: center;
    }
`;

export const MobileGridItem = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 400 ? "43vh" : "41vh"};
        padding-left: 4%;
        padding-right: 4%;
    }
`;

export const MobileLotusTypography = styled(Typography)`
    && {
        color: white;
        font-family: Patrick Hand;
        font-weight: bold;
    }
`;

export const MobileAuthenticTypography = styled(Typography)`
    && {
        margin-top: 15px;
        color: white;
        font-family: Montserrat;
        font-weight: 600;
    }
`;

export const MobileLunchDinnerGrid = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
    }
`;

export const MobileLunchDinnerButtons = styled(Button)`
    && {
        text-align: center;
        border-color: transparent;
        border-radius: 5px;
        font-family: Source Sans Pro;
        font-size: 16px;
        font-weight: bold;
        padding: 2%;
        color: white;
        &:hover {
            transform: perspective(700px) translateZ(25px);
            border-color: white;
        }
    }
`;

export const MobileSocialIconGrids = styled(Grid)`
    && {
        position: absolute;
        top: ${({top}) => top};
        left: ${({left}) => left};
    }
`;

export const MobileStyledIconButton = styled(IconButton)`
    && {
        transition: transform 0.2s;
        &:hover {
            transform: translateY(${({animationdirection}) => animationdirection === "up" ? "-5px" : "5px"});
        }
    }
`;

export const MobileStyledFacebookIcon = styled(CgFacebook)`
    font-size: 22px;
    color: white;
`;

export const MobileStyledInstagramIcon = styled(InstagramIcon)`
    && {
        font-size: 24px;
        color: white;
    }
`;

export const MobileStyledDownArrowIcon = styled(CgArrowLongDown)`
    font-size: 24px;
    color: white;
`;



// About Us Styled Components
export const AboutUsGridContainer = styled(Grid)`
    && {
        padding-top: ${({width}) => width > 1700 ? "5%" : width > 1200 ? "5%" : "0%"};
        padding-bottom: ${({width}) => width > 1700 ? "5%" : width > 1200 ? "5%" : "0%"};
        padding-right: ${({width}) => width > 1700 ? "3%" : width > 1200 ? "2%" : "4%"};
        padding-left: ${({width}) => width > 1700 ? "3%" : width > 1200 ? "2%" : "4%"};
        margin-top: ${({width}) => width > 900 ? "-1%" : "0%"};
    }
`;



// View Lunch and Dinner Image Styled Components
export const ParallaxGrids = styled(Grid)`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: ${({paddingleft}) => paddingleft};
        padding-right: ${({paddingright}) => paddingright};
    }
`;

export const ParallaxLunchDinnerDiv = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
`;

export const ParallaxLunchDinnerButtons = styled(Button)`
    && {
        text-align: center;
        border-color: transparent;
        border-radius: 5px;
        font-family: Source Sans Pro;
        font-size: 15px;
        font-weight: bold;
        padding: 3%;
        color: white;
        &:hover {
            transform: perspective(700px) translateZ(25px);
        }
    }
`;

export const MobileGrid = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;



// Hours Styled Components
export const HoursGridItem = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 1500 ? "2.5%" : width > 1000 ? "3%" : "6%"};
        margin-bottom: ${({width}) => width > 1000 ? "0.5%" : "2%"};
    }
`;

export const HoursSectionTypography = styled(Typography)`
    && {
        font-family: Source Sans Pro;
    }
`;

export const LunchDinnerGridItem = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 1000 ? "1%" : "2%"};
    }
`;



// Address and Phone Styled Components
export const CallInformationGridItem = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 1500 ? "2%" : width > 1000 ? "4%" : "8%"};
    }
`;

export const PhoneAnchor = styled.a`
    text-decoration: none;
    color: #4277FF;
    &: hover {
        text-decoration: underline;
    }
`;



// Delivery Logo Styled Components
export const DeliveryGridContainer = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
    }
`;

export const DeliveryGridItem = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 1500 ? "2%" : width > 1000 ? "4%" : "8%"};
    }
`;

export const LogoGridItem = styled(Grid)`
    && {
        padding-right: ${({width}) => width > 1000 ? "1%" : "2%"};
        padding-left: ${({width}) => width > 1000 ? "1%" : "2%"};
        margin-top: ${({width}) => width > 1000 ? "1.5%" : "4%"};
    }
`;

export const StyledAnchor = styled.a`
    display: block;
    transition: transform 0.2s;
    &:hover {
        transform: perspective(700px) translateZ(25px);
    }
`;

export const StyledImage = styled.img`
    border-radius: 10px;
`;



// Image Styled Components
export const ImageSectionContainer = styled(Grid)`
    && {
        margin-top: ${({width}) => width > 700 ? "3%" : "7.5%"};
    }
`;

export const MobileCaptionTypography = styled(Typography)`
    && {
        position: absolute;
        font-family: Source Sans Pro;
        color: white;
        margin-left: 2%;
        margin-top: 1%;
    }
`;

export const StyledImagePic = styled.img`
    display: block;
`;



// Testimonial Styled Components
export const TestimonialContainer = styled(Grid)`
    && {
        padding-left: 5%;
        padding-right: 5%;
        margin-top: ${({width}) => width > 1240 ? "5%" : width > 700 ? "8%" : "13%"};
        margin-bottom: ${({width}) => width > 1240 ? "5%" : width > 700 ? "8%" : "13%"};
    }
`;

export const CarouselGridItem = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
    }
`;

export const CarouselTypography = styled(Typography)`
    && {
        font-family: Source Sans Pro;
        padding-right: 5%;
        padding-left: 5%;
    }
`;

export const CarouselPagePrevNextButton = styled(IconButton)`
    && {
        color: #FE8900;
    }
`;

export const CarouselPageButtons = styled(IconButton)`
    && {
        color: ${({testimonialpage, testimonialbuttonnumber}) => testimonialpage === testimonialbuttonnumber ? "#FE8900" : "#F8CB59"};
    }
`;

export const StyledCircleIcon = styled(CircleIcon)`
    && {
        font-size: 18px;
    }
`;
